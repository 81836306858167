import React from 'react';
import hiring from '../../assets/images/advertisement/recruitment1.png';
import aboutUs from '../../assets/images/advertisement/recruitment2.png';

const Advertisement = () => {
    return (
        <div className='flex flex-col md:flex-row w-full'>
            <div 
                className='flex flex-col items-center text-center bg-cover bg-center w-full md:w-1/2 h-[400px] md:h-[611px]'
                style={{ backgroundImage: `url(${hiring})` }}
            >
                <h1 className='font-bold text-2xl md:text-4xl lg:text-7xl mt-16 px-4 md:px-0'>Join Our Team!</h1>
                <p className='text-sm md:text-2xl lg:text-[27px] px-4 lg:px-16'>
                    ProSynergy Inc. is always looking for promising talent to simplify and innovate client solutions.
                </p>
                <a 
                    href="/careers" 
                    className="text-black hover:text-white border border-2 border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-base md:text-lg w-[150px] md:w-[217px] py-2.5 text-center mt-4 md:mt-8">
                        View Careers
                </a>
            </div>
            <div 
                className='flex flex-col items-center text-center bg-cover bg-center w-full md:w-1/2 h-[400px] md:h-[611px]'
                style={{ backgroundImage: `url(${aboutUs})` }}
            >
                <h1 className='font-bold text-2xl md:text-4xl lg:text-7xl mt-16 px-4 md:px-0'>Get to know PSI</h1>
                <p className='text-sm md:text-2xl lg:text-[27px] px-4 lg:px-16'>
                    Learn about how we started and
                    the people that complete the company.
                </p>
                <a 
                    href='/company' 
                    className="text-black hover:text-white border border-2 border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-base md:text-lg w-[150px] md:w-[217px] py-2.5 text-center mt-4 md:mt-8">
                        Learn More
                </a>
            </div>
        </div>
    );
}

export default Advertisement;

import React from 'react';
import CompanyLogo from '../../assets/images/companyLogoWithSlogan.png';
import FooterLogo from '../../assets/images/footerLogo.png';
import gmail from '../../assets/images/footer/gmail.png';
import linkedin from '../../assets/images/footer/linkedIn.png';
import facebook from '../../assets/images/footer/facebook.png';

const Footer = () => {
    return (
        <div className='bg-[#202020] text-white sm:px-10 px-5 pt-10'
            style={{ 
                backgroundImage: `url(${FooterLogo})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right center'  // Align the background image to the right
            }}
        >
            <div className='flex sm:flex-row flex-col justify-between w-full h-auto lg:h-[400px]'>
                <div className='flex flex-col gap-5 w-full h-full'>
                    <img 
                        src={CompanyLogo} 
                        className='
                            w-[153px] h-[37.08px]
                            md:w-[209.2px] md:h-[50.7px]
                            lg:w-[326px] lg:h-[79px]
                        ' 
                        alt='company logo with slogan' 
                    />
                    <div className='flex sm:flex-row flex-col gap-5 md:mt-10'>
                        <ul className='flex flex-col w-full gap-1 max-sm:order-2 order-1'>
                            <li className='text-sm sm:text-lg font-semibold'>Business Hours:</li>
                            <li className='text-base sm:text-xl font-thin'>Monday - Friday</li>
                            <li className='text-base sm:text-xl font-thin'>8:30 AM - 5:30 PM</li>
                            <li className='font-semibold sm:text-xl mt-10'>Branches:</li>
                            <li>Head Office - Quezon City  |  Cebu  |  Davao</li>
                            <li className='mb-5'>Dagupan  |  Cagayan De Oro  |  Tuguegarao</li>
                        </ul>
                        <div className='flex flex-col w-full h-full max-sm:order-1 order-2'>
                            <ul className='flex flex-col md:justify-end md:items-end items-start w-full gap-2 text-base sm:text-lg'>
                                <li>+63 917 102 5133</li>
                                <li>+63 917 157 3882</li>
                                <li className='my-4'><a href="https://mail.google.com/mail/?view=cm&fs=1&to=psi.rems@prosynergy.ph&su=Subject%20Here&body=Body%20Here" target="_blank" rel="noreferrer">psi.rems@prosynergy.ph</a></li>
                                <li>
                                    <ul className='flex flex-row w-full'>
                                        <li className='p-1.5 rounded-lg text-center'>
                                            <a href="https://mail.google.com/mail/?view=cm&fs=1&to=psi.rems@prosynergy.ph&su=Subject%20Here&body=Body%20Here" target="_blank" rel="noreferrer">
                                                <img src={gmail} alt='gmail' className='w-[30px] h-[30px]'/>
                                            </a>
                                        </li>
                                        <li className='p-1.5 rounded-lg text-center'>
                                            <a href="https://www.linkedin.com/company/prosynergyinc/" target="_blank" rel="noreferrer">
                                                <img src={linkedin} alt='linkedin' className='w-[30px] h-[30px]'/>
                                            </a>
                                        </li>
                                        <li className='p-1.5 rounded-lg text-center'>
                                            <a href='https://www.facebook.com/PSIQuezonAvenue' target="_blank" rel="noreferrer">
                                                <img src={facebook} alt='facebook' className='w-[30px] h-[30px]'/>
                                            </a>
                                        </li>
                                        {/* <li className='p-1.5 rounded-lg text-center'>
                                            <a href='https://youtube.com/'>
                                                <i className="fa-solid text-black fa-lg fa-play"></i>                                
                                            </a>
                                        </li> */}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col sm:flex-row w-full justify-center sm:justify-between border-t-[0.6px] border-white mt-auto py-2.5'>
                <p className='text-base sm:text-lg md:order-1 order-2 my-2'>© 2024. ProSynergy Inc.</p>
                <ul className='flex flex-row gap-5 text-sm sm:text-lg md:order-2 order-1 max-sm:border-b-2 max-sm:pb-2'>
                    {/* <li>
                        <a href='/term-of-use'>
                            Terms of Use
                        </a>
                    </li> */}
                    <li>
                        <a href='/privacy-notice'>
                            Privacy Notice
                        </a>
                    </li>
                    <li><a href='/careers'>Careers</a></li>
                    {/* <li><a href='/login'>Admin</a></li> */}
                </ul>
            </div>
        </div>
    )
}

export default Footer;

import React from 'react';
import hand from '../../assets/images/introduction/handshake.gif';
import paperwork from '../../assets/images/introduction/copy.gif';
import support from '../../assets/images/introduction/chat-bot.gif';

const Introduction = () => {
    return (
        <div className='flex flex-col px-4 sm:px-10 w-full py-6 sm:py-12'>
            <p className='font-semibold text-[23px] md:text-3xl lg:text-4xl'>
                Why choose us?
            </p>
            <div className='flex flex-col sm:flex-row gap-6 sm:gap-10 w-full justify-center py-6 sm:py-5'>
                <div className='flex flex-col items-center sm:w-1/3 w-full text-center'>
                    <img 
                        src={hand} 
                        alt='hand logo' 
                        className='w-24 h-24 sm:w-48 sm:h-48 object-contain'
                    />
                    <p className='font-semibold text-base md:text-lg lg:text-xl mt-2'>
                        Fast and Reliable
                    </p>
                    <p className='text-sm md:text-base mt-1'>
                        Our dedicated team of experts work diligently to expedite the processing of your crucial documents, offering swift solutions that you can trust.
                    </p>
                </div>

                <div className='flex flex-col items-center sm:w-1/3 w-full text-center'>
                    <img 
                        src={paperwork} 
                        alt='paperwork logo' 
                        className='w-24 h-24 sm:w-48 sm:h-48 object-contain'
                    />
                    <p className='font-semibold text-base md:text-lg lg:text-xl mt-2'>
                        Paperwork Simplified
                    </p>
                    <p className='text-sm md:text-base mt-1'>
                        Our team of professionals are dedicated to simplifying the entire documentation journey, from gathering the necessary information to preparing and processing the required forms.
                    </p>
                </div>

                <div className='flex flex-col items-center sm:w-1/3 w-full text-center'>
                    <img 
                        src={support} 
                        alt='support logo' 
                        className='w-24 h-24 sm:w-48 sm:h-48 object-contain'
                    />
                    <p className='font-semibold text-base md:text-lg lg:text-xl mt-2'>
                        Proactive Customer Support
                    </p>
                    <p className='text-sm md:text-base mt-1'>
                        Whether you have questions about processes, need guidance on specific requirements, or seek clarification on government regulations, our customer support is just a call or message away.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Introduction;

// src/utils/utils.js
export const DateFormatter = (str) => {
    const date = new Date(str);
    // Format date in 'en-US' locale
    const formattedDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
    }).format(date);
    
    return formattedDate;
};

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ucwords } from '../../utils/Ucwords';
import Swal from 'sweetalert2';

const CreateScheduleModal = ({ isOpen, onClose, selectedDate }) => {
    const [errors, setErrors] = useState({});
    const [clients, setClients] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    // Ensure the zoom field is properly included in formData
    const [formData, setFormData] = useState({
        client_id: '',
        user_id: '',
        schedule_date: selectedDate || '',
        schedule_time: '',
        sched_status: '0',
        zoom: ''
    });


    const validateForm = () => {
        const newErrors = {};
        if (!formData.client_id) {
            newErrors.client_id = 'Please select client name';
        }
        if (!formData.user_id) {
            newErrors.user_id = 'Please select account officer';
        }
        if (!formData.zoom) {
            newErrors.zoom = 'Please select account officer';
        }
        if (!formData.user_id) {
            newErrors.user_id = 'Please select account officer';
        }
        // if (!formData.sched_status) {
        //     newErrors.sched_status = 'Please select status';
        // }
        return newErrors;
    };

    // Update formData when selectedDate changes
    useEffect(() => {
        if (selectedDate) {
            setFormData(prevState => ({
                ...prevState,
                schedule_date: selectedDate
            }));
        }
    }, [selectedDate]);

    // Handle change event for the dropdown
    const handleChange = (event) => {
        const { id, value } = event.target;
        const date = new Date();
        const dateTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    
        // Update formData state
        setFormData(prevState => ({
            ...prevState,
            [id]: value,
            // Update schedule_time if client_id is changed
            ...(id === 'client_id' && { schedule_time: dateTime })
        }));
    
        // Update selectedClient if client_id dropdown is changed
        if (id === 'client_id') {
            const client = clients.find(c => c.id.toString() === value);
            setSelectedClient(client);
        }
    };
    
    // Store schedule
    const handleSubmit = async (event) => {
        event.preventDefault();

        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No token found. Please log in.');
            return;
        }
        try {
            await axios.post('http://localhost:3002/schedules', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            
            // Reset form data
            setFormData({
                client_id: '',
                user_id: '',
                schedule_date: selectedDate,
                schedule_time: '',
                sched_status: '',
                zoom : ''
            });
            onClose(); // Close the modal after successful submission

            Swal.fire({
                title: 'Success!',
                text: 'Schedule created successfully',
                icon: 'success',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                window.location.reload();
            });
        } catch (error) {
            console.error('Error creating schedule:', error);
            Swal.fire({
                title: 'Error!',
                text: 'An error occurred while processing your request.',
                icon: 'error',
                showConfirmButton: true
            });
        }
    };

    // Fetch clients
    const fetchClients = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get('http://localhost:3002/consultation', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setClients(response.data);
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    // Fetch employees
    const fetchEmployees = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get('http://localhost:3002/employees', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setEmployees(response.data);
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    // Fetch data on component mount
    useEffect(() => {
        if (isOpen) {
            fetchClients();
            fetchEmployees();
        }
    }, [isOpen]);

    // Handle modal close
    const handleClose = () => {
        setFormData({
            client_id: '',
            user_id: '',
            schedule_date: selectedDate,
            schedule_time: '',
            sched_status: '',
            zoom : ''
        });
        onClose();
    };

    return (
        <div 
            id="static-modal" 
            className={`fixed overflow-y-auto overflow-x-hidden inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 max-h-full ${isOpen ? 'flex' : 'hidden'}`} 
            data-modal-backdrop="static" 
            tabIndex="-1" 
            aria-hidden={!isOpen}
        >
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className="relative bg-white rounded-lg shadow">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900" id="modal-title">
                            Create Schedule
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            onClick={handleClose}
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-4 md:p-5 space-y-4">
                        <form onSubmit={handleSubmit}>
                            <div className='flex flex-col w-full gap-3'>
                                <div className='flex flex-row gap-5 w-full'>
                                    <div className="w-full">
                                        <label htmlFor="client_name" className="block font-medium text-gray-700">Client Name</label>
                                        <select
                                            id="client_id"
                                            value={formData.client_id}
                                            onChange={handleChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                                        >
                                            <option value="">Select Client Name</option>
                                            {clients.map((client) => (
                                                <option key={client.id} value={client.id}>
                                                    {ucwords(client.firstname)} {ucwords(client.lastname)}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.client_id && (
                                            <p className="text-red-600">{errors.client_id}</p>
                                        )}
                                    </div>
                                    <div className="w-full">
                                        <label htmlFor="Inquiry" className="block font-medium text-gray-700">Inquiry</label>
                                        <input
                                            type="text"
                                            id="Inquiry"
                                            value={ucwords(selectedClient?.service || '')}  // Ensure default value
                                            className="bg-gray-200 block w-full border-gray-300 rounded-lg shadow-sm focus:ring-none focus:border-none"
                                            readOnly
                                        />
                                    </div> 
                                </div>
                                <div className="w-full">
                                    <label htmlFor="user_id" className="block font-medium text-gray-700">Account Officer</label>
                                    <select
                                        id="user_id"
                                        value={formData.user_id}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                                    >
                                        <option value="">Select Account Officer</option>
                                        {employees.map((employee) => (
                                            <option key={employee.id} value={employee.id}>
                                                {ucwords(employee.firstname)} {ucwords(employee.lastname)}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.user_id && (
                                        <p className="text-red-600">{errors.user_id}</p>
                                    )}
                                </div>
                                <div className="w-full">
                                    <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
                                    <input
                                        type="text"
                                        id="date"
                                        value={formData.schedule_date || ''}  // Ensure default value
                                        className="bg-gray-200 block w-full border-gray-300 rounded-lg shadow-sm focus:ring-none focus:border-none"
                                        readOnly
                                    />

                                </div>    
                                <div className="w-full">
                                    <label htmlFor="zoom" className="block font-medium text-gray-700">Zoom Link</label>
                                    <textarea
                                        id="zoom"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                                        rows="3"
                                        value={formData.zoom}
                                        onChange={handleChange}
                                    ></textarea>
                                    {errors.zoom && (
                                        <p className="text-red-600">{errors.zoom}</p>
                                    )}
                                </div>
                                {/* <div className="w-full">
                                    <label htmlFor="sched_status" className="block font-medium text-gray-700">Status</label>
                                    <select
                                        id="sched_status"
                                        value={formData.sched_status}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                                    >
                                        <option value="">Select a Status</option>
                                        <option value="0">On-going</option>
                                        <option value="1">Completed</option>
                                        <option value="2">Cancelled</option>
                                    </select>
                                    {errors.sched_status && (
                                        <p className="text-red-600">{errors.sched_status}</p>
                                    )}
                                </div>   */}
                            </div>
                            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b">
                                <button 
                                    type="submit" 
                                    className="text-white bg-[var(--customRed)] hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                >
                                    Save
                                </button>
                                <button 
                                    type="button" 
                                    className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-red-700 focus:z-10 focus:ring-4 focus:ring-gray-100" 
                                    onClick={handleClose}
                                >
                                    Close
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateScheduleModal;

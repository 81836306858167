import React from 'react'
import remBg from '../../assets/images/service/remBg.png';
import Rem from '../../components/user/Rem';
import Navbar from '../../components/common/Navbar';
import Footer from '../../components/common/Footer';

const UserRemPage = () => {
    return (
        <div>
            <Navbar />
            <div 
                className='relative flex flex-col justify-center lg:h-[50vh] bg-cover lg:bg-contain bg-no-repeat mt-[60px] lg:mt-[31px] bg-center z-0'
                style={{ 
                    backgroundImage: `url(${remBg})`,
                    backgroundBlendMode: 'multiply',
                }}
            >
                <div className="absolute top-0 left-0 w-full h-full lg:h-[43.5vh] bg-blend-[multiply] bg-[#82091F] bg-opacity-[0.65] z-1"></div>
                <div className='relative text-white px-4 sm:px-8 md:px-12 lg:px-16 py-4 sm:py-6 md:py-8 lg:py-10 z-2'>
                    <h1 className='text-xl sm:text-xl md:text-2xl lg:text-3xl font-bold'>Real Estate Services</h1>
                    <p className='mt-2 text-sm sm:text-base md:text-lg lg:text-xl'>
                        We provide expert guidance and personalized solutions by delivering exceptional service, and help you navigate your Real Estate Properties with confidence and ease.                 
                    </p>
                </div>
            </div>
            <div className='py-5'>
                <Rem/>
            </div>
            <Footer />
        </div>
    )
}

export default UserRemPage

import React from 'react';
import CareerSlogan from '../../assets/images/carreer/CareerSlogan.png';
import CareerFront from '../../assets/images/carreer/CareerFront.png';

const CarreerHero = () => {
    return (
        <div className="lg:h-screen h-auto flex flex-col justify-center items-center sm:flex-row py-20">
            <div className='w-full sm:ml-5 sm:px-0 px-5'>
                <img src={CareerSlogan} alt="slogan" className="sm:h-auto sm:w-auto w-full max-w-[821.36px] max-h-[398.18px]" />
            </div>
            <div className='w-full'>
                <img src={CareerFront} alt="slogan" className="w-full h-auto mt-12" />
            </div>
        </div>
    );
};

export default CarreerHero;

import React, { useState, useEffect, useRef } from 'react';
import Hero from '../../components/common/Hero';
import Navbar from '../../components/common/Navbar';
import Service from '../../components/common/Service';
import Consultation from '../../components/common/Consultation';
import Introduction from '../../components/common/Introduction';
import Portfolio from '../../components/common/Portfolio';
import Advertisement from '../../components/common/Advertisement';
import Footer from '../../components/common/Footer';

const UserDashboardPage = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const heroRef = useRef(null);

  const handleScroll = () => {
    const heroHeight = heroRef.current.clientHeight;
    const scrollPosition = window.scrollY;
    if (scrollPosition > heroHeight) {
      setShowNavbar(true);
    } else {
      setShowNavbar(false);
    };
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      {showNavbar && <Navbar />}
      <div ref={heroRef}>
        <Hero />
      </div>
      <Service />
      <Consultation />
      <Introduction />
      <Portfolio />
      <Advertisement />
      <Footer />
    </div>
  );
};

export default UserDashboardPage;

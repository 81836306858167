import React, { useState } from 'react';
import companyLogo from '../../assets/images/logo_name_black.png';
import Logo from '../../assets/images/logo.png';
import ConsultationModal from '../user/ConsultationModal';
import { Link, useLocation } from 'react-router-dom';
import gmail from '../../assets/images/footer/gmail.png';
import linkedin from '../../assets/images/footer/linkedIn.png';
import facebook from '../../assets/images/footer/facebook.png';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isConsultModalOpen, setIsConsultModalOpen] = useState(false);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleLinkClick = () => {
        setIsMenuOpen(false);
    };

    const linkClass = "block py-2 px-3 text-gray-900 text-[23px] font-medium hover:text-green-600";
    const ActiveClass = "block py-2 px-3 text-green-600 text-[23px] font-medium";

    const location = useLocation();

    const isActive = (path) => location.pathname === path;
    return (
        <>
            <ConsultationModal isOpen={isConsultModalOpen} onClose={() => setIsConsultModalOpen(false)} />
            <nav className="bg-white border-gray-200 w-full fixed top-0 left-0 right-0 z-20 shadow-xl p-3">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto">
                    <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                        <img 
                            src={companyLogo} 
                            className="
                                w-[227.89px] h-[27.82px]
                                md:w-[288.72px] md:h-[35.25px]
                                lg:w-[344px] lg:h-[42px]
                            " 
                            alt="company Logo" 
                        />
                    </a>                  
                    <button 
                        type="button" 
                        onClick={handleMenuToggle}
                        className="inline-flex items-center p-2 w-9 md:w-11 justify-center text-sm text-gray-900 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" 
                        aria-expanded={isMenuOpen ? "true" : "false"}
                        aria-controls="drawer-example"
                    >
                        <span className="sr-only">Toggle navigation menu</span>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                        </svg>
                    </button>
                    <div className="hidden w-full lg:block md:w-auto" id="navbar-default">
                        <ul className="font-[500] flex p-4 md:p-0 flex-row space-x-8 rtl:space-x-reverse">
                            <li>
                                <Link
                                    to="/services"
                                    className={`${isActive('/services') ? ActiveClass : linkClass}`}
                                >
                                    Services
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/company"
                                    className={`${isActive('/company') ? ActiveClass : linkClass}`}
                                >
                                    Company
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/contact-us"
                                    className={`${isActive('/contact-us') ? ActiveClass : linkClass}`}
                                >
                                    Contact Us
                                </Link>
                            </li>
                            <li>
                                <button 
                                    onClick={() => setIsConsultModalOpen(true)}
                                    type="button"
                                    className="block py-2 px-3 text-white bg-[#214A21] max-w-[252px] max-h-[54px] font-semibold text-[23px] rounded-full"
                                >
                                    CONSULT WITH US
                                </button>
                            </li>
                        </ul>
                    </div>

                    <div id="drawer-example" className={`fixed top-0 left-0 z-40 h-full p-4 overflow-y-auto transition-transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} bg-[#202020] w-full`} tabIndex="-1" aria-labelledby="drawer-label">
                        <h5 id="drawer-label" className="inline-flex items-center mb-4 text-base font-semibold text-gray-500">
                            <img src={Logo} 
                                className="
                                    w-[227.89px] h-[27.82px]
                                    md:w-[288.72px] md:h-[35.25px]
                                    lg:w-[344px] lg:h-[42px]
                                "
                                alt="company Logo" 
                            />
                        </h5>
                        <button 
                            type="button" 
                            onClick={handleMenuToggle}
                            className="text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-[34px] w-11 h-11 absolute top-2.5 right-2.5 flex items-center justify-center"
                            aria-controls="drawer-example"
                        >
                            <i className="fa-solid fa-xmark"></i>   
                            <span className="sr-only">Close menu</span>
                        </button>
                        <ul className='flex flex-col gap-3 w-full text-white p-5'>
                            <li className='text-2xl'>
                                <Link
                                    to="/"
                                    className={`block text-[23px] font-medium ${isActive('/') ? 'text-green-500' : 'text-white'}`}
                                    onClick={handleLinkClick}
                                >
                                    Home
                                </Link>
                            </li>
                            <hr className="h-px bg-white border-0" />
                            <li className='text-2xl hover:text-green-500'>
                                <Link
                                    to="/services"
                                    className={`block text-[23px] font-medium ${isActive('/services') ? 'text-green-500' : 'text-white'}`}
                                    onClick={handleLinkClick}
                                >
                                    Services
                                </Link>
                            </li>
                            <hr className="h-px bg-white border-0" />
                            <li className='text-2xl hover:text-green-500'>
                                <Link
                                    to="/company"
                                    className={`block text-[23px] font-medium ${isActive('/company') ? 'text-green-500' : 'text-white'}`}
                                    onClick={handleLinkClick}
                                >
                                    Company
                                </Link>
                            </li>
                            <hr className="h-px bg-white border-0" />
                            <li className='text-2xl hover:text-green-500'>
                                <Link
                                    to="/contact-us"
                                    className={`block text-[23px] font-medium ${isActive('/contact-us') ? 'text-green-500' : 'text-white'}`}
                                    onClick={handleLinkClick}
                                >
                                    Contact Us
                                </Link>
                            </li>
                            <hr className="h-px bg-white border-0" />
                            <li 
                                className='text-xl hover:text-green-500'
                                onClick={() => setIsConsultModalOpen(true)}
                            >
                                CONSULT WITH US
                            </li>
                        </ul>
                        <div className='absolute bottom-4 left-1/2 transform -translate-x-1/2 w-full flex flex-col gap-5 justify-center'>
                            <ul className='flex flex-row justify-center w-full'>
                                <li className='p-1.5 rounded-lg text-center'>
                                    <a href="https://mail.google.com/mail/?view=cm&fs=1&to=psi.rems@prosynergy.ph&su=Subject%20Here&body=Body%20Here" target="_blank" rel="noreferrer">
                                        <img src={gmail} alt='gmail' className='w-[30px] h-[30px]'/>
                                    </a>
                                </li>
                                <li className='p-1.5 rounded-lg text-center'>
                                    <a href='https://www.linkedin.com/company/prosynergyinc/' target="_blank" rel="noreferrer">
                                        <img src={linkedin} alt='linkedin' className='w-[30px] h-[30px]'/>                               
                                    </a>
                                </li>
                                <li className='p-1.5 rounded-lg text-center'>
                                    <a href='https://www.facebook.com/PSIQuezonAvenue' target="_blank" rel="noreferrer">
                                        <img src={facebook} alt='facebook' className='w-[30px] h-[30px]'/>                               
                                    </a>
                                </li>
                                {/* <li className='border bg-white p-1.5 rounded-lg w-9 text-center'>
                                    <a href='https://youtube.com/' target="_blank" rel="noopener noreferrer">
                                        <i className="fa-solid text-black fa-lg fa-play"></i>                                
                                    </a>
                                </li> */}
                            </ul>
                            <ul className='flex flex-row justify-center w-full gap-2 text-white'>
                                {/* <li>
                                    <a href='#'>
                                        Terms of Use
                                    </a>
                                </li>
                                | */}
                                <li>
                                    <a href='/privacy-notice'>
                                        Privacy Policy
                                    </a>
                                </li>
                                |
                                <li>
                                    <a href='/careers'>
                                        Career
                                    </a>
                                </li>
                                {/* |
                                <li>
                                    <a href='/login'>
                                        Admin
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;

import React from 'react';
import chattle from '../../assets/images/service/chattel.png';
import realEstates from '../../assets/images/service/realestates.png';

const Service = () => {
    return (
        <div>
            <div className="flex flex-col w-full px-5 py-10 gap-8 lg:px-12 mt-10">
                <p className="font-semibold pt-8
                    text-[23px]
                    md:text-[35px]
                    lg:text-4xl"
                >
                    Our Services
                </p>
                <div className="flex flex-col gap-5 w-full sm:flex-row lg:gap-10">
                    <img 
                        src={realEstates} 
                        alt="Real Estate Services" 
                        className="
                            rounded-2xl 
                            w-full
                            h-full
                            h-[185px]
                            md:w-[376.23px] 
                            lg:w-[659px] 
                        " 
                    />
                    <div className="flex flex-col w-full lg:py-5 lg:py-10 gap-3">
                        <p className="
                            text-gray-400 
                            font-[400] 
                            text-xl
                            md:text-[23px]
                            lg:text-[25px]"
                        >
                            Real Estate Services
                        </p>
                        <h3 className="font-bold text-[23px] md:text-[36.5px] lg:text-[45px] lg:mb-5">
                            Your Property, Our priority
                        </h3>
                        <p className="text-[15px] md:text-[20px] lg:text-[22px]">
                            We provide expert guidance and personalized solutions by delivering exceptional service to help you navigate your Real Estate Properties with confidence and ease.
                        </p>
                        <p className="underline text-red-900 mb-4 text-sm sm:text-[17px]">
                        <a href='/real-estates-services'>Transfer of Ownership</a>, <a href='/real-estates-services'>Property - Real Estate Appraisal</a>
                        </p>
                        <a 
                            href='/real-estates-services' 
                            className="
                                text-center px-5 text-white rounded-full bg-[var(--customRed)] hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-red-300 font-medium 
                                text-sm h-[40px] py-2
                                md:text-[20.8px] md:w-[227px] md:h-[48.84px] md:py-3
                                lg:text-[23px] lg:w-[251px] lg:h-[54px] lg:py-4
                            ">
                            Learn More
                        </a>
                    </div>
                </div>
                <div className="flex flex-col lg:gap-5 w-full sm:flex-row-reverse py-16 lg:gap-10">
                    <img 
                        src={chattle} 
                        alt="Auto & Chattel Services" 
                        className="
                            rounded-2xl 
                            w-full
                            h-full
                            h-[185px]
                            md:w-[376.23px] 
                            lg:w-[659px] 
                        " 
                    />
                    <div className="flex flex-col w-full py-5 gap-3">
                        <p className="
                            text-gray-400 
                            font-[400] 
                            text-xl
                            md:text-[23px]
                            lg:text-[25px]"
                        >
                            Auto & Chattel Services
                        </p>
                        <h3 className="font-bold text-[23px] md:text-[36.5px] lg:text-[45px] lg:mb-5 lg:leading-[2.9rem]">
                            Drive hassle-free with our easy and convenient auto & chattel solutions
                        </h3>
                        <p className="text-[15px] md:text-[20px] lg:text-[22px]">
                            We offer comprehensive Auto & Chattel Services designed to meet your unique needs. Our expert team provides top-notch solutions, ensuring the security and efficiency of your auto assets.
                        </p>
                        <p className="underline text-red-900 mb-4 text-sm sm:text-[17px]">
                            <a href='/auto'>Vehicle Car History</a>, <a href='/auto'>LTO Annotation</a>, <a href='/auto'>Registration and Renewal</a>                        
                        </p>
                        <a 
                            href='/auto' 
                            className="
                                text-center px-5 text-white rounded-full bg-[var(--customRed)] hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-red-300 font-medium 
                                text-sm h-[40px] py-2
                                md:text-[20.8px] md:w-[227px] md:h-[48.84px] md:py-3
                                lg:text-[23px] lg:w-[251px] lg:h-[54px] lg:py-4
                            ">
                            Learn More
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Service;

import React, { useState, useEffect } from 'react';
import CareerModal from './CareerModal';
import axios from 'axios';

const CareerPosition = () => {
    const [openIndexes, setOpenIndexes] = useState([]);
    const [isCareerModalOpen, setIsCareerModalOpen] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState('');
    const [hirings, setHirings] = useState([]);

    const handleToggle = (index) => {
        setOpenIndexes(prevOpenIndexes => 
            prevOpenIndexes.includes(index) 
                ? prevOpenIndexes.filter(i => i !== index) 
                : [...prevOpenIndexes, index]
        );
    };

    const fetchHirings = async () => {
        try {
            const response = await axios.get('http://localhost:3002/careerPosition');
            setHirings(response.data);
        } catch (error) {
            console.error('Error fetching hirings:', error);
        }
    };

    useEffect(() => {
        fetchHirings();
    }, []);

    
    return (
        <>            
            <CareerModal isOpen={isCareerModalOpen} onClose={() => setIsCareerModalOpen(false)} position={selectedPosition}/>
            <div className='flex flex-col justify-center w-full bg-[#202020] text-white h-auto sm:p-12 p-5 gap-5 text-left'>
                <h1 className='sm:text-5xl text-3xl font-normal'>Join our Team!</h1>
                <p className='text-xl sm:text-[27px]'>ProSynergy Inc. is always looking for promising talent to simplify and innovate client solutions.</p>
                <p className='text-xl sm:text-[27px] mb-5'>Every member of our organization, no matter the role, contributes to the growth and success of the company. Join us and experience a collaborative environment that encourages your growth.</p>
                
                {hirings.map((hiring, index) => (
                    <div key={index} className='lg:w-[1189px] w-full border border-gray-200 shadow-lg rounded-xl mx-auto bg-white'>
                        <h2>
                            <button 
                                type="button" 
                                className="flex items-center justify-between w-full border border-2 p-4 rounded-xl font-medium text-left gap-3" 
                                onClick={() => handleToggle(index)} 
                                aria-expanded={openIndexes.includes(index)} 
                            >
                                <div className='flex flex-col w-full'>
                                    <span className='sm:text-3xl font-semibold text-black'>{hiring.position}</span>
                                    <span className='text-black sm:text-2xl text-sm'>{hiring.branch}</span>
                                </div>
                                <span
                                    onClick={(e) => {
                                        e.stopPropagation();  // Prevent the button click from toggling the accordion
                                        setSelectedPosition(hiring.position);
                                        setIsCareerModalOpen(true);
                                    }}
                                    className="focus:outline-none text-white bg-[#214A21] hover:bg-green-800 focus:ring-4 focus:ring-green-300 sm:text-xl text-sm font-medium rounded-full sm:w-[204.52px] w-32 py-1.5 text-center"
                                >
                                    Apply Now
                                </span>
                            </button>
                        </h2>
                        <div 
                            className={`${openIndexes.includes(index) ? 'block' : 'hidden'} text-black`}>
                            <div className="px-5 mt-5">
                                <h5 className='sm:text-2xl text-xl font-semibold'>About the Job</h5>
                                <p className="mb-2">{hiring.description}</p>
                            </div>
                            <div className="px-5 py-2">
                                <h5 className='sm:text-2xl text-xl font-semibold'>Responsibilities</h5>
                                <p className="mb-2">{hiring.responsibilities}</p>
                            </div>
                            <div className="px-5 py-2">
                                <h5 className='sm:text-2xl text-xl font-semibold'>Qualifications</h5>
                                <p className="mb-2">{hiring.qualifications}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default CareerPosition;

import React from 'react'
import Navbar from '../../components/common/Navbar';
import Footer from '../../components/common/Footer';
import ContactUs from '../../components/user/ContactUs';
import Faq from '../../components/user/Faq';
import Offices from '../../components/user/Offices';

const UserContactUsPage = () => {
    return (
        <div>   
            <Navbar />
                <ContactUs/>
                <Faq />
                <Offices />
            <Footer />
        </div>
    )
}

export default UserContactUsPage

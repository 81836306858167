import React, { useState } from 'react';
import slogan from '../../assets/images/slogan.png'
import Logo from '../../assets/images/logo.png';
import ConsultationModal from '../user/ConsultationModal';
import VideoBanner from '../../assets/video/PSIvideo.mp4';
import HeroModal from '../user/HeroModal';
import { Link, useLocation } from 'react-router-dom';
import gmail from '../../assets/images/footer/gmail.png';
import linkedin from '../../assets/images/footer/linkedIn.png';
import facebook from '../../assets/images/footer/facebook.png';

const Hero = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isConsultModalOpen, setIsConsultModalOpen] = useState(false);
    const [isHeroModalOpen, setIsHeroModalOpen] = useState(false);

    const handleLinkClick = () => {
        setIsMenuOpen(false);
    };

    const location = useLocation();
    const isActive = (path) => location.pathname === path;
    return (
        <>
            <ConsultationModal isOpen={isConsultModalOpen} onClose={() => setIsConsultModalOpen(false)} />
            <HeroModal isOpen={isHeroModalOpen} onClose={() => setIsHeroModalOpen(false)} />
            <div className='relative flex flex-col h-screen w-full'>
                <video 
                    className='absolute top-0 left-0 w-full h-screen object-cover z-[-1]' 
                    src={VideoBanner} 
                    autoPlay 
                    muted 
                    loop 
                ></video>
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-[0.65] z-1"></div>
                <div className="relative z-2">
                    <nav>
                        <div 
                            className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4"
                        >
                            <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                                <img 
                                    src={Logo} 
                                    className="
                                        w-[227.89px] h-[27.82px]
                                        md:w-[288.72px] md:h-[35.25px]
                                        lg:w-[344px] lg:h-[42px]
                                    " 
                                    alt="company Logo" 
                                />
                            </a>
                            <button 
                                type="button" 
                                onClick={() => setIsMenuOpen(!isMenuOpen)}
                                className="inline-flex items-center p-2 md:w-11 justify-center text-sm text-white rounded-lg lg:hidden hover:bg-gray-100 focus:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-600" 
                                aria-expanded={isMenuOpen ? "true" : "false"}
                                aria-controls="drawer-example"
                            >
                                <span className="sr-only">Open main menu</span>
                                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                                </svg>
                            </button>
                            <div className="hidden w-full lg:block md:w-auto" id="navbar-default">
                                <ul className="flex p-4 md:p-0 flex-row space-x-8 rtl:space-x-reverse">
                                    <li>
                                        <a 
                                            href="/services" 
                                            className="block py-2 px-3 text-white text-[23px] font-medium hover:text-green-600" 
                                        >
                                            Services
                                        </a>
                                    </li>
                                    <li>
                                        <a 
                                            href="/company" 
                                            className="block py-2 px-3 text-white text-[23px] font-medium hover:text-green-600"
                                        >
                                            Company
                                        </a>
                                    </li>
                                    <li>
                                        <a 
                                            href="/contact-us" 
                                            className="block py-2 px-3 text-white text-[23px] font-medium hover:text-green-600"
                                        >
                                            Contact Us
                                        </a>
                                    </li>

                                    <li>
                                        <button 
                                            type='button'
                                            className="block py-2 px-3 text-white bg-[#214A21] max-w-[252px] max-h-[54px] font-semibold text-[23px] rounded-full"
                                            onClick={() => setIsConsultModalOpen(true)}
                                        >
                                            CONSULT WITH US
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div id="drawer-example" className={`fixed top-0 left-0 z-40 h-full p-4 overflow-y-auto transition-transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} bg-[#202020] w-full`} tabIndex="-1" aria-labelledby="drawer-label">
                                <h5 id="drawer-label" className="inline-flex items-center mb-4 text-base font-semibold text-gray-500">
                                    <img src={Logo} 
                                        className="
                                            w-[227.89px] h-[27.82px]
                                            md:w-[288.72px] md:h-[35.25px]
                                            lg:w-[344px] lg:h-[42px]
                                        " 
                                        alt="company Logo" 
                                    />
                                </h5>
                                <button 
                                    type="button" 
                                    onClick={() => setIsMenuOpen(false)}
                                    className="text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-[34px] w-11 h-11 absolute top-2.5 right-2.5 flex items-center justify-center"
                                    aria-controls="drawer-example"
                                >
                                    <i className="fa-solid fa-xmark"></i>   
                                    <span className="sr-only">Close menu</span>
                                </button>
                                <ul className='flex flex-col gap-3 w-full text-white p-5'>
                                    <li className='text-2xl'>
                                        <Link
                                            to="/"
                                            className={`block text-[23px] font-medium ${isActive('/') ? 'text-green-500' : 'text-white'}`}
                                            onClick={handleLinkClick}
                                        >
                                            Home
                                        </Link>
                                    </li>
                                    <hr className="h-px bg-white border-0" />
                                    <li className='text-2xl hover:text-green-500'>
                                        <Link
                                            to="/services"
                                            className={`block text-[23px] font-medium ${isActive('/services') ? 'text-green-500' : 'text-white'}`}
                                            onClick={handleLinkClick}
                                        >
                                            Services
                                        </Link>
                                    </li>
                                    <hr className="h-px bg-white border-0" />
                                    <li className='text-2xl hover:text-green-500'>
                                        <Link
                                            to="/company"
                                            className={`block text-[23px] font-medium ${isActive('/company') ? 'text-green-500' : 'text-white'}`}
                                            onClick={handleLinkClick}
                                        >
                                            Company
                                        </Link>
                                    </li>
                                    <hr className="h-px bg-white border-0" />
                                    <li className='text-2xl hover:text-green-500'>
                                        <Link
                                            to="/contact-us"
                                            className={`block text-[23px] font-medium ${isActive('/contact-us') ? 'text-green-500' : 'text-white'}`}
                                            onClick={handleLinkClick}
                                        >
                                            Contact Us
                                        </Link>
                                    </li>
                                    <hr className="h-px bg-white border-0" />
                                    <li 
                                        className='text-xl hover:text-green-500'
                                        onClick={() => setIsConsultModalOpen(true)}
                                    >
                                        CONSULT WITH US
                                    </li>
                                </ul>
                                <div className='absolute bottom-4 left-1/2 transform -translate-x-1/2 w-full flex flex-col gap-5 justify-center'>
                                    <ul className='flex flex-row justify-center w-full'>
                                        <li className='p-1.5 rounded-lg text-center'>
                                        <a href="https://mail.google.com/mail/?view=cm&fs=1&to=psi.rems@prosynergy.ph&su=Subject%20Here&body=Body%20Here" target="_blank" rel="noreferrer">
                                            <img src={gmail} alt='gmail' className='w-[30px] h-[30px]'/>
                                        </a>
                                        </li>
                                        <li className='p-1.5 rounded-lg text-center'>
                                            <a href='https://www.linkedin.com/company/prosynergyinc/' target="_blank" rel="noreferrer">
                                                <img src={linkedin} alt='linkedin' className='w-[30px] h-[30px]'/>                               
                                            </a>
                                        </li>
                                        <li className='p-1.5 rounded-lg text-center'>
                                            <a href='https://www.facebook.com/PSIQuezonAvenue' target="_blank" rel="noreferrer">
                                                <img src={facebook} alt='facebook' className='w-[30px] h-[30px]'/>                               
                                            </a>
                                        </li>
                                        {/* <li className='p-1.5 rounded-lg text-center'>
                                            <a href='https://youtube.com/'>
                                                <i className="fa-solid text-black fa-lg fa-play"></i>                                
                                            </a>
                                        </li> */}
                                    </ul>
                                    <ul className='flex flex-row justify-center w-full gap-2 text-white'>
                                        {/* <li>
                                            <a href='#'>
                                                Terms of Use
                                            </a>
                                        </li>
                                        | */}
                                        <li>
                                            <a href='/privacy-notice'>
                                                Privacy Policy
                                            </a>
                                        </li>
                                        |
                                        <li>
                                            <a href='/careers'>
                                                Career
                                            </a>
                                        </li>
                                        {/* |
                                        <li>
                                            <a href='/login'>
                                                Admin
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </nav>
                    <div 
                        className="flex flex-col justify-center items-center w-full min-h-screen py-auto pb-10 gap-8 px-5 text-center text-white"

                    >
                        <p className='text-[27px]'>What do we do?</p>
                        <img src={slogan} alt='slogan' className='sm:w-[932px] sm:h-[154px] w-full'/>
                        <div className='sm:block hidden text-lg leading-5'>
                            <p>We specialize in simplifying and expediting real estate and automobile documentation</p>
                            <p>processes. Our goal is to save you time and effort, ensuring that all your paperwork is</p>
                            <p>handled efficiently and accurately, so you can focus on what matters most.</p>
                        </div>
                        <div className='sm:hidden block leading-5'>
                            <p className='text-center text-lg '>We specialize in simplifying and expediting real estate and automobile documentation processes. Our goal is to save you time and effort, ensuring that all your paperwork is handled efficiently and accurately, so you can focus on what matters most.</p>
                        </div>
                        <button 
                            onClick={() => setIsHeroModalOpen(true)}
                            type="button" 
                            className="py-2.5 px-5 me-2 mb-2 text-sm sm:text-lg font-medium text-white focus:outline-none rounded-full border-2 border-gray-200 hover:bg-gray-100 hover:text-black focus:z-10 focus:ring-4 focus:ring-gray-100 hover:font-medium"
                        >
                            Watch our Video
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Hero;

import React, { useState } from 'react';
import Image from '../../assets/images/login_image.png';
import Logo from '../../assets/images/logo_name_black.png';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

export const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // LoginPage.js
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await axios.post('http://localhost:3002/login', { email, password });
        const { message, success, token, user } = response.data;

        if (success) {
            // Save user data and token to local storage
              localStorage.setItem('token', token);
              localStorage.setItem('user', JSON.stringify(user));          

            // Show success message
            Swal.fire({
                title: 'Success!',
                text: message,
                icon: 'success',
                showConfirmButton: false,
                timer: 3000
            });

            // Redirect to the dashboard or home page
            navigate('/admin/dashboard');
        } else {
            Swal.fire({
              title: 'Error!',
              text: message,
              icon: 'error',
              showConfirmButton: false,
              timer: 3000
          });
        }
    } catch (err) {
        console.error('Error during login:', err);
        setError('An error occurred. Please try again.');
    }
  };


  return (
    <div className='flex flex-col sm:flex-row w-full h-screen'>
      <div className='h-screen sm:w-full sm:h-full'>
        <img src={Image} alt="images" className="h-full w-full object-cover" />
      </div>
      <div className="flex justify-center items-center w-full h-screen">
        <form onSubmit={handleSubmit} className="w-full max-w-md p-6 bg-white rounded-lg shadow-xl">
          <div className="flex justify-center mb-8">
            <img src={Logo} alt="logo" className="h-16" />
          </div>
          <h1 className="text-center text-xl font-medium mb-2">Log in to your account</h1>
          <p className="text-center text-gray-600 mb-6">to continue to Web Portal</p>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email Address</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-input mt-1 block w-full border-gray-300 rounded-lg shadow-sm focus:ring-green-800 focus:border-green-800 py-2.5 p-2"
              placeholder="prosynergy@email.com"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-input mt-1 block w-full border-gray-300 rounded-lg shadow-sm focus:ring-green-800 focus:border-green-800 py-2.5 p-2"
              placeholder="********"
              required
            />
          </div>
          <button type="submit" className="w-full bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-800 text-white font-medium rounded-lg py-2.5">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import Swal from 'sweetalert2';

const CreateServiceModal = ({ isOpen, onClose, onSuccess }) => {
    const [formData, setFormData] = useState({
        code: '',
        service: ''
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            const token = localStorage.getItem('token'); // Ensure this token is correct and valid
            const response = await fetch('http://localhost:3002/services', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include the token if required by the API
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Unknown error');
            }

            const result = await response.json();
            console.log(result);

            // Call the onSuccess callback to refresh the employee list
            if (onSuccess) onSuccess();

            // Reset form data and close modal
            setFormData({
                code: '',
                service: '',
            });
            onClose();

            Swal.fire({
                title: 'Success!',
                text: 'Service created successfully',
                icon: 'success',
                showConfirmButton: false,
                timer: 3000
            });

        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleClose = () => {
        // Reset form data
        setFormData({
            code: '',
            service: ''
        });
        // Call the passed onClose function
        onClose();
    };

    return (
        <>
            <div id="static-modal" className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ${isOpen ? 'flex' : 'hidden'}`} data-modal-backdrop="static" tabIndex="-1" aria-hidden="true">
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                            <h3 className="text-xl font-semibold text-gray-900">
                                Create Service Modal
                            </h3>
                            <button
                                type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                                onClick={handleClose}
                            >
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4">
                            <form 
                                onSubmit={handleSubmit}
                            >
                                <div className='flex flex-col  w-full'>
                                    <div className="mb-4 w-full">
                                        <label htmlFor="code" className="block text-sm font-medium text-gray-700">Code</label>
                                        <input type="text" id="code" 
                                            value={formData.code} 
                                            onChange={handleChange} 
                                            className="form-input mt-1 block w-full border-gray-300 rounded-lg shadow-sm focus:ring-green-800 focus:border-green-800" required
                                        />
                                    </div>
                                    <div className="mb-4 w-full">
                                        <label htmlFor="service" className="block text-sm font-medium text-gray-700">Service</label>
                                        <input type="text" id="service" 
                                            value={formData.service} 
                                            onChange={handleChange} 
                                            className="form-input mt-1 block w-full border-gray-300 rounded-lg shadow-sm focus:ring-green-800 focus:border-green-800" required 
                                        />
                                    </div>    
                                </div>
                            
                                <div className="flex items-center pt-3 border-t border-gray-200 rounded-b">
                                    <button type="submit" className="text-white bg-[var(--customRed)] hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Save</button>
                                    <button type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-red-700 focus:z-10 focus:ring-4 focus:ring-gray-100" onClick={handleClose}>Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateServiceModal;

import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import TopBar from '../../components/admin/TopBar';
import CreateScheduleModal from '../../components/admin/CreateScheduleModal';
import axios from 'axios';

const CalendarPage = () => {
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [events, setEvents] = useState([]);

    const openCreateModal = () => setIsCreateModalOpen(true);
    const closeCreateModal = () => setIsCreateModalOpen(false);

    const handleDateClick = (arg) => {
        console.log(arg);
        setModalData({ selectedDate: arg.dateStr }); 
        openCreateModal(true);
    };
    
    const handleEventClick = (info) => {
        const { title, start, end, extendedProps } = info.event;

            // alert(`Event clicked: ${info.event.title}`);
            // You can access more information about the event using info.event
            console.log(extendedProps);
    };

    const fetchSchedules = async () => {
        const token = localStorage.getItem('token'); // Or however you're storing your token
        try {
            const response = await axios.get('http://localhost:3002/schedules', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const formattedEvents = response.data.map(schedule => {
                let color; // Declare color with `let`
            
                // Check if schedule.sched_status is a string or number and compare accordingly
                if (schedule.sched_status === '0' || schedule.sched_status === 0) {
                    color = '#FFA500'; // Orange
                } else if (schedule.sched_status === '1' || schedule.sched_status === 1) {
                    color = '#008000'; // Green
                } else if (schedule.sched_status === '2' || schedule.sched_status === 2) {
                    color = '#ff0000'; // Red
                }
                // Ensure the returned object includes the color property
                return {
                    color: color,
                    c_id : schedule.client_id,
                    title: schedule.service,
                    start: new Date(schedule.schedule_date), // Convert to Date object
                    end: new Date(schedule.schedule_date),   // Convert to Date object
                };
            });
            
            
            setEvents(formattedEvents);
    
        } catch (error) { 
            console.error('Error fetching schedules:', error);
        }
    };
    
    useEffect(() => {
        fetchSchedules();
    }, []);
    
    return (
        <>
            <TopBar />
            <CreateScheduleModal isOpen={isCreateModalOpen} onClose={closeCreateModal} selectedDate={modalData?.selectedDate} />
            <div className="p-5 mt-16 h-screen">
                <div className="flex pb-2 justify-between border-b-2">
                    <div className="font-semibold text-xl">
                        <label htmlFor="">My Calendar</label>
                    </div>
                </div>
                <div className='py-4'>
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        events={events}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                    />
                </div>
            </div>
        </>
    );
};

export default CalendarPage;

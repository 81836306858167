import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
// import { FreeMode } from 'swiper/modules';
import { Autoplay, FreeMode } from 'swiper/modules';

import eastwest from '../../assets/images/portfolio/eastwest.png';
// import securitybank from '../../assets/images/portfolio/securitybank.png';
import ytbBank from '../../assets/images/portfolio/ytbBank.png';
import jaccs from '../../assets/images/portfolio/jaccs.png';
import tonix from '../../assets/images/portfolio/tonix.png';
import orix from '../../assets/images/portfolio/orix.png';
// import pnb from '../../assets/images/portfolio/pnb.png';
import bhf from '../../assets/images/portfolio/bhf.png';
import sumisho from '../../assets/images/portfolio/sumisho.png';
import rbank from '../../assets/images/portfolio/rbank.png';
import firstUnited from '../../assets/images/portfolio/firstUnited.png';
// import inLife from '../../assets/images/portfolio/inLife.png';
import brb from '../../assets/images/portfolio/brb.png';
// import msb from '../../assets/images/portfolio/msb.png';
// import psbank from '../../assets/images/portfolio/psbank.png';

const imageSize = {
    width: '185.88px',
};

const images = [
    { src: eastwest, alt: 'eastwest' },
    // { src: securitybank, alt: 'security bank' },
    { src: ytbBank, alt: 'ytbBank' },
    { src: jaccs, alt: 'jaccs' },
    { src: tonix, alt: 'tonix' },
    { src: orix, alt: 'orix', className: 'bg-white rounded-lg' },
    // { src: pnb, alt: 'pnb', className: 'rounded-lg'},
    { src: bhf, alt: 'bhf', className: 'rounded-lg'},
    { src: sumisho, alt: 'sumisho', className: 'rounded-lg'},
    { src: rbank, alt: 'robinson bank', className: 'rounded-lg'},
    { src: firstUnited, alt: 'first united', className: 'rounded-lg'},
    // { src: inLife, alt: 'inLife', className: 'rounded-lg'},
    { src: brb, alt: 'brb', className: 'rounded-lg'},
    // { src: msb, alt: 'msb', className: 'rounded-lg'},
    // { src: psbank, alt: 'psbank', className: 'rounded-lg'},
];

const Portfolio = () => {
    return (
        <div className='flex flex-col w-full lg:h-[71vh] h-auto bg-[#202020]'>
            <div className='flex flex-col md:flex-col lg:flex-row w-full gap-2'>
                <div className='flex flex-col gap-3 text-white w-full px-4 pt-12 pb-5 md:px-10'>
                    <p className='font-semibold text-2xl md:text-4xl'>Our Client Portfolio</p>
                    <p className='text-base md:text-[22px]'>
                        We’ve worked with some of the country’s leading financial institutions,
                        and continue to grow our partnerships across other enterprises.
                    </p>
                </div>
                <div className='w-full'></div>
            </div>
            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                loop={true}
                autoplay={{
                    delay: 0,
                    disableOnInteraction: false,
                }}
                speed={2000} // Transition speed in milliseconds
                effect="slide" // Correct way to set the 'slide' effect
                freeMode={true}
                pagination={{
                    clickable: true,
                }}
                modules={[Autoplay, FreeMode]}
                breakpoints={{
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    1024: {
                        slidesPerView: 6,
                        spaceBetween: 30,
                    },
                    1280: {
                        slidesPerView: 6,
                        spaceBetween: 30,
                    },
                }}
                className="mySwiper mt-5 mb-10"
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img src={image.src} className={`${imageSize} ${image.className ? image.className : ''}`} alt={image.alt} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default Portfolio;

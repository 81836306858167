import React, { useState } from 'react'
import rem1 from '../../assets/images/service/rem1.png';
import rem2 from '../../assets/images/service/rem2.png';
import rem3 from '../../assets/images/service/rem3.png';
import rem4 from '../../assets/images/service/rem4.png';
import rem5 from '../../assets/images/service/rem5.png';
import rem6 from '../../assets/images/service/rem6.png';
import ConsultationModal from './ConsultationModal';

const Rem = () => {
    const [isOpenRem, setIsOpenRem] = useState([false]);
    const [isConsultModalOpen, setIsConsultModalOpen] = useState(false);

    const toggleAccordionRem = (index) => {
        const newisOpenRem = [...isOpenRem];
        newisOpenRem[index] = !newisOpenRem[index];
        setIsOpenRem(newisOpenRem);
    };

    return (
        <>
            <ConsultationModal isOpen={isConsultModalOpen} onClose={() => setIsConsultModalOpen(false)} />
            <div className='grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 sm:gap-10 gap-5 px-5 w-full sm:px-10 mb-10'>
                <div
                    className='h-full'
                >
                    <div 
                        className='relative flex flex-row justify-center items-end bg-cover bg-no-repeat bg-center h-52 rounded-t-xl'
                        style={{ backgroundImage: `url(${rem1})` }}
                    >
                        <div className="absolute inset-0 bg-black bg-opacity-50 rounded-t-xl z-0"></div>
                        <p 
                            className="relative lg:text-[23px] md:text-[20px] text-[17px] text-white text-center leading-tight mb-3 z-10"
                        >
                            Registration and/or
                            <br />
                            Cancellation of Real Estate
                            <br />
                            Mortgage
                        </p>
                    </div>
                    <div
                        className='rounded-b-xl'
                        style={{ boxShadow: 'inset -10px 0px 10px -10px rgba(0, 0, 0, 0.15), inset 10px 0px 10px -10px rgba(0, 0, 0, 0.15)'}}
                    >
                        <div className="px-5 pt-3">
                            <p className={`mb-3 font-normal text-gray-700 ${isOpenRem[1] ? '' : 'line-clamp-4'}`}>
                                This service helps officially record or remove a mortgage on a property. It ensures that the mortgage is legally recognized or removed from public records once the debt is paid, keeping property titles accurate and up-to-date.
                            </p>
                        </div>
                        <div 
                            className={`${isOpenRem[1] ? 'block' : 'hidden'}`}
                        >
                            <div className='flex flex-col gap-5 px-5'>
                                {/* <div>
                                    <p className='font-semibold text-gray-700'><i className="fa-solid fa-lg fa-file-circle-check"></i> Requirements:</p>
                                    <ul className='list-disc px-5'>
                                        <li>Original Title</li>
                                        <li>Notarized REM Documents</li>
                                        <li>DST Payment</li>
                                        <li>Tax Declaration</li>
                                        <li>Tax Clearance</li>
                                    </ul>
                                </div> */}
                                <div className='flex flex-row items-center justify-center'>
                                    <button 
                                        type="button" 
                                        className="text-white bg-[#214A21] focus:ring-2 w-full lg:w-[311px] lg:h-[46.01px] text-[17px] focus:outline-none focus:ring-green-300 rounded-full py-2 text-center font-medium"
                                        onClick={() => setIsConsultModalOpen(true)}
                                        >
                                        Send an Inquiry
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button 
                            type="button" 
                            className="flex flex-col text-[#446444] items-center justify-center w-full p-5 font-medium rounded-xl shadow-xl" 
                            aria-expanded={isOpenRem[1]}
                            onClick={() => toggleAccordionRem(1)}
                        >   
                            
                            <span>{isOpenRem[1] ? 'See Less' : 'See More'}</span>
                            <i className={`fa-solid ${isOpenRem[1] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                        </button>
                    </div>
                </div>

                <div
                    className='h-full'
                >
                    <div 
                        className='relative flex flex-row justify-center items-end bg-cover bg-no-repeat bg-center h-52 rounded-t-xl'
                        style={{ backgroundImage: `url(${rem2})` }}
                    >
                        <div className="absolute inset-0 bg-black bg-opacity-50 rounded-t-xl z-0"></div>
                        <p 
                            className="relative lg:text-[23px] md:text-[20px] text-[17px] text-white text-center leading-tight mb-3 z-10"
                        >
                            Title Verification
                        </p>
                    </div>
                    <div
                        className='rounded-b-xl'
                        style={{ boxShadow: 'inset -10px 0px 10px -10px rgba(0, 0, 0, 0.15), inset 10px 0px 10px -10px rgba(0, 0, 0, 0.15)'}}
                    >
                        <div className="px-5 pt-3">
                            <p className={`mb-3 font-normal text-gray-700 ${isOpenRem[2] ? '' : 'line-clamp-4'}`}>
                                Facilitates title verification, including trace-back of ownership to ensure the authenticity of the title and determine whether such title is free from liens and/encumbrances.
                            </p>
                        </div>
                        <div 
                            className={`${isOpenRem[2] ? 'block' : 'hidden'}`}
                        >
                            <div className='flex flex-col gap-5 px-5'>
                                {/* <div>
                                    <p className='font-semibold text-gray-700'><i className="fa-solid fa-lg fa-file-circle-check"></i> Requirements:</p>
                                    <ul className='list-disc px-5'>
                                        <li>Original Title</li>
                                        <li>Notarized REM Documents</li>
                                        <li>DST Payment</li>
                                        <li>Tax Declaration</li>
                                        <li>Tax Clearance</li>
                                    </ul>
                                </div> */}
                                <div className='flex flex-row items-center justify-center'>
                                    <button 
                                        type="button" 
                                        className="text-white bg-[#214A21] focus:ring-2 w-full lg:w-[311px] lg:h-[46.01px] text-[17px] focus:outline-none focus:ring-green-300 rounded-full py-2 text-center font-medium"
                                        onClick={() => setIsConsultModalOpen(true)}
                                        >
                                        Send an Inquiry
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button 
                            type="button" 
                            className="flex flex-col text-[#446444] items-center justify-center w-full p-5 font-medium rounded-xl shadow-xl" 
                            aria-expanded={isOpenRem[2]}
                            onClick={() => toggleAccordionRem(2)}
                        >   
                            
                            <span>{isOpenRem[2] ? 'See Less' : 'See More'}</span>
                            <i className={`fa-solid ${isOpenRem[2] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                        </button>
                    </div>
                </div>

                <div
                    className='h-full'
                >
                    <div 
                        className='relative flex flex-row justify-center items-end bg-cover bg-no-repeat bg-center h-52 rounded-t-xl'
                        style={{ backgroundImage: `url(${rem3})` }}
                    >
                        <div className="absolute inset-0 bg-black bg-opacity-50 rounded-t-xl z-0"></div>
                        <p 
                            className="relative lg:text-[23px] md:text-[20px] text-[17px] text-white text-center leading-tight mb-3 z-10"
                        >
                            Property - Real Estate                            
                            <br />
                            Appraisal
                        </p>
                    </div>
                    <div
                        className='rounded-b-xl'
                        style={{ boxShadow: 'inset -10px 0px 10px -10px rgba(0, 0, 0, 0.15), inset 10px 0px 10px -10px rgba(0, 0, 0, 0.15)'}}
                    >
                        <div className="px-5 pt-3">
                            <p className={`mb-3 font-normal text-gray-700 ${isOpenRem[3] ? '' : 'line-clamp-4'}`}>
                                Provides an independent assessment of property value and evaluates the current physical condition of the property through an extensive technical evaluation by a licensed and competent appraiser. A comprehensive report, validated with data and information, is required to determine the current market value of the property.
                            </p>
                        </div>
                        <div 
                            className={`${isOpenRem[3] ? 'block' : 'hidden'}`}
                        >
                            <div className='flex flex-col gap-5 px-5'>
                                {/* <div>
                                    <p className='font-semibold text-gray-700'><i className="fa-solid fa-lg fa-file-circle-check"></i> Requirements:</p>
                                    <ul className='list-disc px-5'>
                                        <li>Original Title</li>
                                        <li>Notarized REM Documents</li>
                                        <li>DST Payment</li>
                                        <li>Tax Declaration</li>
                                        <li>Tax Clearance</li>
                                    </ul>
                                </div> */}
                                <div className='flex flex-row items-center justify-center'>
                                    <button 
                                        type="button" 
                                        className="text-white bg-[#214A21] focus:ring-2 w-full lg:w-[311px] lg:h-[46.01px] text-[17px] focus:outline-none focus:ring-green-300 rounded-full py-2 text-center font-medium"
                                        onClick={() => setIsConsultModalOpen(true)}
                                        >
                                        Send an Inquiry
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button 
                            type="button" 
                            className="flex flex-col text-[#446444] items-center justify-center w-full p-5 font-medium rounded-xl shadow-xl" 
                            aria-expanded={isOpenRem[3]}
                            onClick={() => toggleAccordionRem(3)}
                        >   
                            
                            <span>{isOpenRem[3] ? 'See Less' : 'See More'}</span>
                            <i className={`fa-solid ${isOpenRem[3] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                        </button>
                    </div>
                </div>

                <div
                    className='h-full'
                >
                    <div 
                        className='relative flex flex-row justify-center items-end bg-cover bg-no-repeat bg-center h-52 rounded-t-xl'
                        style={{ backgroundImage: `url(${rem4})` }}
                    >
                        <div className="absolute inset-0 bg-black bg-opacity-50 rounded-t-xl z-0"></div>
                        <p 
                            className="relative lg:text-[23px] md:text-[20px] text-[17px] text-white text-center leading-tight mb-3 z-10"
                        >
                            Annual Real Estate Tax
                            <br />
                            Payment
                        </p>
                    </div>
                    <div
                        className='rounded-b-xl'
                        style={{ boxShadow: 'inset -10px 0px 10px -10px rgba(0, 0, 0, 0.15), inset 10px 0px 10px -10px rgba(0, 0, 0, 0.15)'}}
                    >
                        <div className="px-5 pt-3">
                            <p className={`mb-3 font-normal text-gray-700 ${isOpenRem[4] ? '' : 'line-clamp-4'}`}>
                                Ensures your property taxes are accurately calculated, paid on time, and documented. It includes managing payments, providing timely reminders, and supporting any tax disputes, allowing you to stay compliant and avoid penalties without the hassle of handling it yourself.
                            </p>
                        </div>
                        <div 
                            className={`${isOpenRem[4] ? 'block' : 'hidden'}`}
                        >
                            <div className='flex flex-col gap-5 px-5'>
                                {/* <div>
                                    <p className='font-semibold text-gray-700'><i className="fa-solid fa-lg fa-file-circle-check"></i> Requirements:</p>
                                    <ul className='list-disc px-5'>
                                        <li>Original Title</li>
                                        <li>Notarized REM Documents</li>
                                        <li>DST Payment</li>
                                        <li>Tax Declaration</li>
                                        <li>Tax Clearance</li>
                                    </ul>
                                </div> */}
                                <div className='flex flex-row items-center justify-center'>
                                    <button 
                                        type="button" 
                                        className="text-white bg-[#214A21] focus:ring-2 w-full lg:w-[311px] lg:h-[46.01px] text-[17px] focus:outline-none focus:ring-green-300 rounded-full py-2 text-center font-medium"
                                        onClick={() => setIsConsultModalOpen(true)}
                                        >
                                        Send an Inquiry
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button 
                            type="button" 
                            className="flex flex-col text-[#446444] items-center justify-center w-full p-5 font-medium rounded-xl shadow-xl" 
                            aria-expanded={isOpenRem[4]}
                            onClick={() => toggleAccordionRem(4)}
                        >   
                            
                            <span>{isOpenRem[4] ? 'See Less' : 'See More'}</span>
                            <i className={`fa-solid ${isOpenRem[4] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                        </button>
                    </div>
                </div>

                <div
                    className='h-full'
                >
                    <div 
                        className='relative flex flex-row justify-center items-end bg-cover bg-no-repeat bg-center h-52 rounded-t-xl'
                        style={{ backgroundImage: `url(${rem5})` }}
                    >
                        <div className="absolute inset-0 bg-black bg-opacity-50 rounded-t-xl z-0"></div>
                        <p 
                            className="relative lg:text-[23px] md:text-[20px] text-[17px] text-white text-center leading-tight mb-3 z-10"
                        >
                            Transfer of Ownership
                        </p>
                    </div>
                    <div                        
                        className='rounded-b-xl'
                        style={{ boxShadow: 'inset -10px 0px 10px -10px rgba(0, 0, 0, 0.15), inset 10px 0px 10px -10px rgba(0, 0, 0, 0.15)'}}
                    >
                        <div className="px-5 pt-3">
                            <p className={`mb-3 font-normal text-gray-700 ${isOpenRem[5] ? '' : 'line-clamp-4'}`}>
                                Facilitates the transfer of ownership from seller to buyer and coordinates with relevant agencies to expedite the process.
                                <br />
                                <br />
                            </p>
                        </div>
                        <div 
                            className={`${isOpenRem[5] ? 'block' : 'hidden'}`}
                        >
                            <div className='flex flex-col gap-5 px-5'>
                                {/* <div>
                                    <p className='font-semibold text-gray-700'><i className="fa-solid fa-lg fa-file-circle-check"></i> Requirements:</p>
                                    <ul className='list-disc px-5'>
                                        <li>Original Title</li>
                                        <li>Notarized REM Documents</li>
                                        <li>DST Payment</li>
                                        <li>Tax Declaration</li>
                                        <li>Tax Clearance</li>
                                    </ul>
                                </div> */}
                                <div className='flex flex-row items-center justify-center'>
                                    <button 
                                        type="button" 
                                        className="text-white bg-[#214A21] focus:ring-2 w-full lg:w-[311px] lg:h-[46.01px] text-[17px] focus:outline-none focus:ring-green-300 rounded-full py-2 text-center font-medium"
                                        onClick={() => setIsConsultModalOpen(true)}
                                        >
                                        Send an Inquiry
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button 
                            type="button" 
                            className="flex flex-col text-[#446444] items-center justify-center w-full p-5 font-medium rounded-xl shadow-xl" 
                            aria-expanded={isOpenRem[5]}
                            onClick={() => toggleAccordionRem(5)}
                        >   
                            
                            <span>{isOpenRem[5] ? 'See Less' : 'See More'}</span>
                            <i className={`fa-solid ${isOpenRem[5] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                        </button>
                    </div>
                </div>

                <div
                    className='h-full'
                >
                    <div 
                        className='relative flex flex-row justify-center items-end bg-cover bg-no-repeat bg-center h-52 rounded-t-xl'
                        style={{ backgroundImage: `url(${rem6})` }}
                    >
                        <div className="absolute inset-0 bg-black bg-opacity-50 rounded-t-xl z-0"></div>
                        <p 
                            className="relative lg:text-[23px] md:text-[20px] text-[17px] text-white text-center leading-tight mb-3 z-10"
                        >
                            Petition for Reconstitution
                        </p>
                    </div>
                    <div
                        className='rounded-b-xl'
                        style={{ boxShadow: 'inset -10px 0px 10px -10px rgba(0, 0, 0, 0.15), inset 10px 0px 10px -10px rgba(0, 0, 0, 0.15)'}}
                    >
                        <div className="px-5 pt-3">
                            <p className={`mb-3 font-normal text-gray-700 ${isOpenRem[6] ? '' : 'line-clamp-4'}`}>
                                The service helps property owners restore lost or destroyed land titles by filing a petition and providing proof of ownership, ensuring the title is accurately reissued in official records.
                            </p>
                        </div>
                        <div 
                            className={`${isOpenRem[6] ? 'block' : 'hidden'}`}
                        >
                            <div className='flex flex-col gap-5 px-5'>
                                {/* <div>
                                    <p className='font-semibold text-gray-700'><i className="fa-solid fa-lg fa-file-circle-check"></i> Requirements:</p>
                                    <ul className='list-disc px-5'>
                                        <li>Original Title</li>
                                        <li>Notarized REM Documents</li>
                                        <li>DST Payment</li>
                                        <li>Tax Declaration</li>
                                        <li>Tax Clearance</li>
                                    </ul>
                                </div> */}
                                <div className='flex flex-row items-center justify-center'>
                                    <button 
                                        type="button" 
                                        className="text-white bg-[#214A21] focus:ring-2 w-full lg:w-[311px] lg:h-[46.01px] text-[17px] focus:outline-none focus:ring-green-300 rounded-full py-2 text-center font-medium"
                                        onClick={() => setIsConsultModalOpen(true)}
                                        >
                                        Send an Inquiry
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button 
                            type="button" 
                            className="flex flex-col text-[#446444] items-center justify-center w-full p-5 font-medium rounded-xl shadow-xl" 
                            aria-expanded={isOpenRem[6]}
                            onClick={() => toggleAccordionRem(6)}
                        >   
                            
                            <span>{isOpenRem[6] ? 'See Less' : 'See More'}</span>
                            <i className={`fa-solid ${isOpenRem[6] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                        </button>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Rem

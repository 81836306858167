import React, { useState, useRef, useEffect } from 'react';
import Logo from '../../assets/images/logo_name.png';
import { useNavigate } from 'react-router-dom';
import { ucwords } from '../../utils/Ucwords';

const TopBar = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [user, setUser] = useState(null);
    const dropdownRef = useRef(null);
    const dropdownButtonRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        try {
            const userData = localStorage.getItem('user');
            if (userData) {
                setUser(JSON.parse(userData));
            }
        } catch (error) {
            console.error('Error parsing user data:', error);
        }
    
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !dropdownButtonRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
    const closeDrawer = () => setIsDrawerOpen(false);
    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    const handleLogout = async (e) => {
        e.preventDefault(); // Prevent the default anchor tag behavior

        try {
            const token = localStorage.getItem('token'); // Retrieve token from local storage
            const response = await fetch('http://localhost:3002/logout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Attach token to headers
                },
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data.message); // Log success message
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                navigate('/login');
            } else {
                console.error('Logout failed:', response.statusText);
                // Optionally, show an alert or message to the user
            }
        } catch (error) {
            console.error('Error logging out:', error);
            // Optionally, show an alert or message to the user
        }
    };

    return (
        <>
            <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-green-800 dark:border-green-700">
                <div className="px-3 py-3 lg:px-5 lg:pl-3">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center justify-start">
                            <button 
                                className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2" 
                                type="button" 
                                data-drawer-target="drawer-navigation" 
                                data-drawer-toggle="drawer-navigation" 
                                aria-controls="drawer-navigation"
                                onClick={toggleDrawer}
                            >
                                <i className="fa-solid fa-xl fa-bars"></i>
                            </button>
                            <a href="/admin/dashboard" className="flex ml-2 md:mr-24">
                                <img src={Logo} alt='prosynergy logo' className='h-6 sm:h-8'/>
                            </a>
                        </div>
                        <div className="flex items-center">
                            <div className="text-white font-medium hidden sm:block">
                                {user ? `${ucwords(user.firstname)} ${ucwords(user.lastname)}` : 'Guest'}
                            </div>
                            <div className="flex items-center ml-3">
                                <button 
                                    type="button" 
                                    className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-green-600" 
                                    aria-expanded={isDropdownOpen} 
                                    onClick={toggleDropdown}
                                    ref={dropdownButtonRef}
                                >
                                    <span className="sr-only">Open user menu</span>
                                    <img className="w-8 h-8 rounded-full" src={user ? user.profilePicture : 'https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg'} alt="user photo" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            {isDrawerOpen && <div className="fixed inset-0 bg-black opacity-50 z-40" onClick={closeDrawer}></div>}
            <div id="drawer-navigation" className={`fixed top-[4.1rem] left-0 z-50 w-72 h-screen p-4 overflow-y-auto transition-transform ${isDrawerOpen ? 'translate-x-0' : '-translate-x-full'} bg-black/80 dark:bg-green-800`} tabIndex="-1" aria-labelledby="drawer-navigation-label">
                <div className="py-4 overflow-y-auto">
                    <ul className="space-y-2 font-medium">
                        <li>
                            <a href="/admin/dashboard" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-green-700 group">
                                <i className="fa-solid w-9 bg-gray-300 dark:text-green-700 p-2 rounded-lg text-center fa-info"></i>
                                <span className="ml-3">Inquiry</span>
                            </a>
                        </li>
                        <li>
                            <a href="/admin/employee" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-green-700 group">
                                <i className="fa-solid bg-gray-300 dark:text-green-700 p-2 rounded-lg w-9 text-center fa-users"></i>
                                <span className="ml-3">Employees</span>
                            </a>
                        </li>
                        <li>
                            <a href="/admin/calendar" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-green-700 group">
                                <i className="fa-solid bg-gray-300 dark:text-green-700 p-2 rounded-lg w-9 text-center fa-calendar"></i>
                                <span className="ml-3">Calendar</span>
                            </a>
                        </li>
                        <li>
                            <a href="/admin/hiring" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-green-700 group">
                                <i className="fa-solid bg-gray-300 dark:text-green-700 p-2 rounded-lg w-9 text-center fa-user"></i>
                                <span className="ml-3">Hiring</span>
                            </a>
                        </li>
                        <li>
                            <a href="/admin/service" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-green-700 group">
                                <i className="fa-solid fa-bell-concierge bg-gray-300 dark:text-green-700 p-2 rounded-lg w-9 text-center"></i>
                                <span className="ml-3">Services</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            {isDropdownOpen && (
                <div 
                    ref={dropdownRef} 
                    className="fixed top-12 px-2 right-0 z-50 w-auto mt-2 bg-white rounded shadow-lg dark:bg-green-700"
                    aria-labelledby="dropdown-button"
                >
                    <div className="px-2 py-2 border-b">
                        <p className="text-sm text-gray-900 dark:text-white">
                            {user ? `${ucwords(user.firstname)} ${ucwords(user.lastname)}` : 'Guest'}
                        </p>
                        <p className="text-sm font-medium text-gray-700 dark:text-white truncate">
                            {user ? `${user.email}` : ''}
                        </p>
                    </div>
                    <ul className="py-1">
                        <li>
                            <a href="#" className="block px-2 py-1 text-sm text-green-300 hover:bg-green-600 hover:rounded">Profile</a>
                        </li>
                        <li>
                            <a 
                                href="#"
                                onClick={handleLogout}
                                className="block px-2 py-1 text-sm text-green-300 hover:bg-green-600 hover:rounded">
                                    Sign out
                            </a>
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
}

export default TopBar;

import React, { useState } from 'react';
import consultationBg from '../../assets/images/consultation/consultationImage.png';
import ConsultationModal from '../user/ConsultationModal';

const Consultation = () => {
  const [isConsultModalOpen, setIsConsultModalOpen] = useState(false);

  return (
    <>
      <ConsultationModal isOpen={isConsultModalOpen} onClose={() => setIsConsultModalOpen(false)} />
      <div 
        className='flex flex-col items-center lg:flex-row lg:items-center gap-5 bg-cover bg-no-repeat bg-center pt-10 h-auto lg:h-[504px] pb-10'
        style={{
          backgroundImage: `url(${consultationBg})`
        }}
      >
        <div className='flex flex-col sm:pl-10 px-5 w-full justify-center'>
          <div className='flex flex-col justify-center w-auto sm:gap-8 gap-5'>
            <h1 className='font-semibold text-white md:[55px] lg:text-[75px] text-4xl'>Your Time is Valuable.</h1>
            <h1 className='font-semibold text-white md:[55px] lg:text-[75px] text-4xl'>Let Us Help.</h1>
            <div className='flex flex-row gap-5'>
              <p className='text-white text-base md:text-2xl lg:text-[25px]'>Book a FREE 45-Minute Consultation Now. Find out how our services can save you time and effort. Schedule your free session today!</p>
              <div className='w-full'></div>
            </div>
            <button 
              type="button" 
              className="text-green-900 bg-white focus:ring-2 sm:w-[311px] sm:h-[61px] text-sm w-48 md:w-56 md:text-xl focus:outline-none focus:ring-green-300 rounded-full lg:text-2xl lg:w-[311px] py-3 text-center font-semibold"
              onClick={() => setIsConsultModalOpen(true)}
            >
                Book Consultation
            </button>
          </div>
          <div className='sm:block hidden w-full'></div>
        </div>
      </div>
    </>
  );
};

export default Consultation;

import React from 'react'
import Navbar from '../../components/common/Navbar';
import Footer from '../../components/common/Footer';
import CompanyStory from '../../components/user/CompanyStory';
// import CompanyLaunching from '../../components/user/CompanyLaunching';
// import CompanyValues from '../../components/user/CompanyValues';

const UserCompanyPage = () => {
    return (
        <div>
            <Navbar />
            <CompanyStory />
            {/* <CompanyLaunching/> */}
            {/* <CompanyValues /> */}
            <Footer />
        </div>
    )
}

export default UserCompanyPage

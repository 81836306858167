import React from 'react'
import Navbar from '../../components/common/Navbar';
import CareerHero from '../../components/user/CareerHero'
import Footer from '../../components/common/Footer'
import CareerPosition from '../../components/user/CareerPosition';
import Testimonial from '../../components/user/Testimonial';

const UserCareerPage = () => {
    return (
        <div>
            <Navbar />
            <CareerHero />
            <CareerPosition />
            <Testimonial />
            <Footer />
        </div>
    )
}

export default UserCareerPage

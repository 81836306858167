import React, { useState } from 'react';
import Swal from 'sweetalert2';
import CareerBg from '../../assets/images/carreer/CareerBg.png';
import Thankyou from '../../assets/images/consultation/thankYou.gif';

const CareerModal = ({ isOpen, onClose, position }) => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        resume: null,
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { id, value, files } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [id]: files ? files[0] : value,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [id]: '',
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.first_name) newErrors.first_name = 'Please enter your first name';
        if (!formData.last_name) newErrors.last_name = 'Please enter your last name';
        if (!formData.email) newErrors.email = 'Please enter a valid email';
        if (!formData.resume) newErrors.resume = 'Please attach your resume';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        const data = new FormData();
        data.append('first_name', formData.first_name);
        data.append('last_name', formData.last_name);
        data.append('email', formData.email);
        data.append('resume', formData.resume);
        data.append('position', position);

        try {
            const response = await fetch('http://localhost:3002/application', {
                method: 'POST',
                body: data,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Unknown error');
            }

            const result = await response.json();
            console.log(result);

            // Reset form data and close modal
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                resume: null,
            });
            onClose();

            Swal.fire({
                imageUrl: Thankyou,
                imageWidth: 150,
                imageHeight: 150,
                imageAlt: 'Thank you gif',
                text: 'We’ll be sending an email to you shortly to confirm your booking request with us. We look forward to making things happen for you!',
                showConfirmButton: false,
                timer: 5000,
            });

        } catch (error) {
            // Reset form data and close modal
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                resume: null,
            });
            onClose();

            Swal.fire({
                imageUrl: Thankyou,
                imageWidth: 150,
                imageHeight: 150,
                imageAlt: 'Thank you gif',
                text: 'We’ll be sending an email to you shortly to confirm your booking request with us. We look forward to making things happen for you!',
                showConfirmButton: false,
                timer: 5000,
            });
        }
    };

    if (!isOpen) return null;

    return (
        <div
            id="static-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 overflow-y-auto overflow-x-hidden max-h-auto"
        >
            <div className='relative flex flex-col lg:flex-row w-full max-w-3xl h-auto bg-white rounded-lg shadow-lg overflow-hidden'>
                <img src={CareerBg} className='md:hidden lg:block block h-[200px] lg:h-full md:h-[300px] w-full lg:w-1/2 object-cover' alt='shake hands' />
                <div className='flex flex-col w-full lg:w-1/2 p-2'>
                    <div className='flex justify-end'>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-8 h-8 max-sm:-mt-[190px] lg:mt-0 ms-auto inline-flex justify-center items-center"
                            onClick={onClose}
                        >
                            <i className="fa-solid fa-circle-xmark fa-lg text-white md:text-black"></i>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className='flex flex-col w-full'>
                        <ul className='sm:px-6 px-2'>
                            <li className='font-semibold text-lg'>Want to join the team?</li>
                            <li className='text-sm py-1'>Send your resume and we’ll get back to you with an update.</li>
                        </ul>
                        <div className='flex flex-row gap-10 w-full px-6'>
                            <form className='flex flex-col gap-1 w-full' onSubmit={handleSubmit}>
                                <div className="mb-1">
                                    <label htmlFor="first_name" className="block text-sm font-medium text-gray-900">First Name</label>
                                    <input
                                        type="text"
                                        id="first_name"
                                        value={formData.first_name}
                                        onChange={handleChange}
                                        className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2 ${errors.first_name ? 'border-red-500' : ''}`}
                                        placeholder="First Name"
                                    />
                                    {errors.first_name && <p className="text-red-500 text-sm">{errors.first_name}</p>}
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="last_name" className="block text-sm font-medium text-gray-900">Last Name</label>
                                    <input
                                        type="text"
                                        id="last_name"
                                        value={formData.last_name}
                                        onChange={handleChange}
                                        className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2 ${errors.last_name ? 'border-red-500' : ''}`}
                                        placeholder="Last Name"
                                    />
                                    {errors.last_name && <p className="text-red-500 text-sm">{errors.last_name}</p>}
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-900">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2 ${errors.email ? 'border-red-500' : ''}`}
                                    />
                                    {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="resume" className="block text-sm font-medium text-gray-900">Resume</label>
                                    {/* <input
                                        type="file"
                                        id="resume"
                                        onChange={handleChange}
                                        className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 ${errors.resume ? 'border-red-500' : ''}`}
                                    /> */}
                                    <input 
                                        className={`block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none  ${errors.resume ? 'border-red-500' : ''}`}
                                        id="resume" 
                                        type="file" 
                                        onChange={handleChange}
                                    />

                                    <p className="mt-1 text-xs text-gray-500" id="file_input_help">PDF (MAX. 5mb).</p>
                                    {errors.resume && <p className="text-red-500 text-sm">{errors.resume}</p>}
                                </div>
                                <div className="flex items-center mb-2">
                                    <input
                                        id="privacy"
                                        type="checkbox"
                                        // checked={formData.privacy}
                                        onChange={handleChange}
                                        required
                                        className="w-4 h-4 text-red-900 bg-gray-100 border-gray-300 rounded focus:ring-red-900"
                                    />
                                    <label htmlFor="privacy" className="ms-2 text-xs font-medium text-gray-900">
                                        I have read and understood the <a href="/privacy-notice" className="text-[#277D3A] underline">Privacy Notice</a> of ProSynergy Inc.
                                    </label>
                                </div>
                                <button
                                    type="submit"
                                    className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center my-4 w-full"
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CareerModal;

import React from 'react'

const Offices = () => {
    return (
        <div className='flex flex-col justify-center items-center gap-5 w-full py-10 px-5 sm:p-20'>
            <h1 className='sm:text-2xl text-xl font-medium'>Our Offices</h1>
            <div className='flex sm:flex-row flex-col gap-6 w-full justify-center items-center'>
                <div className='w-full'>
                    <p className='text-sm text-[#214A21] font-semibold'>HEAD OFFICE</p>
                    <p className='text-base	'>2nd Floor, First Zenith Building 1468 Quezon Ave.
                    Diliman, Quezon City, Metro Manila, Philippines</p>
                </div>
                <div className='w-full'>
                    <p className='text-sm text-[#214A21] font-semibold'>CEBU BRANCH</p>
                    <p className='text-base	'>3rd Floor Unit 08 Avon Plaza 80 Osmeňa Blvd., Capitol Site, Cebu City</p>
                </div>
                <div className='w-full'>
                    <p className='text-sm text-[#214A21] font-semibold'>DAVAO BRANCH</p>
                    <p className='text-base	'>3rd Floor Units 2A&B JJT Realty Building, Lacson cor. Porras Sts., Obrero, Davao City</p>
                </div>
            </div>
            <div className='flex sm:flex-row flex-col gap-6 w-full justify-center items-center'>
                <div className='w-full'>
                    <p className='text-sm text-[#214A21] font-semibold'>NORTHERN BUREAU BRANCH</p>
                    <p className='text-base'>2nd Floor Unit 201 ARB Corporate Center, Tapuac District, Dagupan City, Pangasinan</p>
                </div>
                <div className='w-full'>
                    <p className='text-sm text-[#214A21] font-semibold'>CAGAYAN DE ORO BRANCH</p>
                    <p className='text-base'>2nd Floor Unit M2 PFIC Building,
                    Aguinaldo Street, Cagayan De Oro City</p>
                </div>
                <div className='w-full'>
                    <p className='text-sm text-[#214A21] font-semibold'>TUGUEGARAO SATELLITE OFFICE</p>
                    <p className='text-base'>Carig Sur, Tuguegarao City, Cagayan
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Offices

import React from 'react'
import Navbar from '../../components/common/Navbar';
import servicesBg from '../../assets/images/service/servicesBg.png';
import Auto from '../../components/user/Auto';
import Rem from '../../components/user/Rem';
import Footer from '../../components/common/Footer';

const UserServicePage = () => {
    return (
        <>
            <div className="mb-10">
                <Navbar />
                <div 
                    className='relative flex flex-col justify-center lg:h-[50vh] bg-cover lg:bg-contain bg-no-repeat mt-[60px] lg:mt-[31px] bg-center z-0'
                    style={{ 
                        backgroundImage: `url(${servicesBg})`,
                        backgroundBlendMode: 'multiply',
                    }}
                >
                    <div className="absolute top-0 left-0 w-full h-full lg:h-[43.5vh] bg-blend-[multiply] bg-[#214A21] bg-opacity-[0.50] z-1"></div>
                    <div className='relative text-white px-4 sm:px-8 md:px-12 lg:px-16 py-4 sm:py-6 md:py-8 lg:py-10 z-2'>
                        <h1 className='text-xl sm:text-xl md:text-2xl lg:text-3xl font-bold'>Our Services</h1>
                        <p className='mt-2 text-sm sm:text-base md:text-lg lg:text-xl'>
                            PSI offers expert, efficient, and dependable auto chattel and real estate services nationwide in the Philippines, providing comprehensive support from loan registration and vehicle history verification to property appraisals and title reconstitution, ensuring seamless operations for financial institutions and the retail market.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col gap-5">
                    <div className="flex flex-col gap-5">
                        <h1 className="px-10 mt-10 font-semibold sm:text-3xl text-xl">Real Estate Services</h1>
                        <Rem />
                    </div>
                    <div className="flex flex-col gap-5">
                        <h1 className="px-10  font-semibold sm:text-3xl text-xl">Auto & Chattel Services</h1>
                        <Auto />
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default UserServicePage

import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination } from 'swiper/modules';

import TethGamboa from '../../assets/images/carreer/TethGamboa.png'
import JpNazareno from '../../assets/images/carreer/JpNazareno.png'
import GladysManalili from '../../assets/images/carreer/GladysManalili.png'
import PrincessCastro from '../../assets/images/carreer/PrincessCastro.png'
import TopQuote from '../../assets/images/carreer/TopQuote.png'
import BottomQuote from '../../assets/images/carreer/BottomQuote.png'
import styled from 'styled-components';

const Testimonial = () => {

    const StyledSwiper = styled(Swiper)`
        .swiper-pagination-bullet {
            background-color: #214A21; /* Set bullet color to green */
        }
        .swiper-pagination-bullet-active {
            background-color: darkgreen; /* Set active bullet color to a darker green */
        }
    `;
    

    return (
        <div className='flex flex-col w-full sm:p-10 p-5 gap-5'>
            <h1 className='sm:text-4xl text-2xl font-semibold max-sm:mb-10'>Why do our people like working in PSI?</h1>
            <StyledSwiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                delay: 35000, // 35 seconds
                disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                loop={true}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className='flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row lg:p-10 gap-2'>
                        <img 
                            src={PrincessCastro} 
                            alt='Princess Castro' 
                            className='
                                w-full h-[302px]
                                md:w-[300px] md:h-auto
                                lg:w-[418px] lg:h-[548px]
                            '
                        />
                        <div className='flex flex-col w-full'>
                            <div className='flex flex-row justify-start items-start'>
                                <img 
                                    src={TopQuote} 
                                    alt='TopQuote' 
                                    className='
                                        w-[20px]
                                        md:w-[35px]
                                        lg:w-[50px]
                                    ' 
                                />
                            </div>
                            <div className='flex flex-row justify-center items-center w-full'>
                                <p className='lg:text-[26px] lg:px-12 md:px-9 text-[15.39px] px-6 lg:leading-9 md:leading-5 leading-2'>
                                    Reflecting on my six years at ProSynergy, l'm deeply grateful for the incredible people I've had the pleasure to work with, especially our dedicated leaders whose unwavering support and guidance have been invaluable. The growth opportunities and shared vision have truly kept me motivated.
                                </p>
                            </div>
                            <div className='flex flex-row justify-end items-end w-full'>
                                <img 
                                    src={BottomQuote} 
                                    alt='TopQuote' 
                                    className='
                                        w-[20px]
                                        md:w-[35px]
                                        lg:w-[50px]
                                    ' 
                                />
                            </div>
                            <div className='lg:px-12 md:px-9 px-6 lg:leading-6 md:leading-4 leading-2'>
                                <h1 className='font-semibold lg:text-[27px] text-[13.85px]'>Princess Castro</h1>
                                <p className='lg:text-[23px] text-[12px]'>Account Management Unit Head, NCR AUTO Accounts</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className='flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row lg:p-10 gap-2'>
                <img 
                            src={JpNazareno} 
                            alt='Jp Nazareno' 
                            className='
                                w-full h-[302px]
                                md:w-[300px]
                                lg:w-[418px] lg:h-[548px]
                                lg:order-2 order-1
                            '
                        />
                        <div className='flex flex-col w-full lg:order-1 order-2'>
                            <div className='flex flex-row justify-start items-start'>
                                <img 
                                    src={TopQuote} 
                                    alt='TopQuote' 
                                    className='
                                        w-[20px]
                                        md:w-[35px]
                                        lg:w-[50px]
                                    ' 
                                />
                            </div>
                            <div className='flex flex-row justify-center items-center w-full'>
                                <p className='lg:text-[26px] lg:px-12 md:px-9 text-[15.39px] px-6 lg:leading-9 md:leading-5 leading-2'>
                                    I’m grateful to ProSynergy Inc. because during the 10 years I have worked here, I learned a lot about the good culture of unity and cooperation to achieve our goals. We are treated like family in the company and I have nothing but sincere thanks to all our directors for not letting us down.
                                </p>
                            </div>
                            <div className='flex flex-row justify-end items-end w-full'>
                                <img 
                                    src={BottomQuote} 
                                    alt='TopQuote' 
                                    className='
                                        w-[20px]
                                        md:w-[35px]
                                        lg:w-[50px]
                                    ' 
                                />
                            </div>
                            <div className='lg:px-12 md:px-9 px-6 lg:leading-6 md:leading-4 leading-2'>
                                <h1 className='font-semibold lg:text-[27px] text-[13.85px]'>JP Nazareno</h1>
                                <p className='lg:text-[23px] text-[12px]'>Assistant Manager for Special Accounts Unit</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row lg:p-10 gap-2'>
                        <img 
                            src={TethGamboa} 
                            alt='teth gamboa' 
                            className='
                                w-full h-[302px]
                                md:w-[300px]
                                lg:w-[418px] lg:h-[548px]
                            '
                        />
                        <div className='flex flex-col w-full'>
                            <div className='flex flex-row justify-start items-start'>
                                <img 
                                    src={TopQuote} 
                                    alt='TopQuote' 
                                    className='
                                        w-[20px]
                                        md:w-[35px]
                                        lg:w-[50px]
                                    ' 
                                />
                            </div>
                            <div className='flex flex-row justify-center items-center w-full'>
                                <p className='lg:text-[26px] lg:px-12 md:px-9 text-[15.39px] px-6 lg:leading-9 md:leading-5 leading-2'>
                                    I love working at Prosynergy Inc. because of the collaborative and innovative environment that fosters creativity and growth. The team is supportive, and the leadership encourages professional development and new ideas. It's inspiring to be part of a company that values its employees and is dedicated to making a positive impact in the industry. Every day at ProSynergy Inc. offers exciting challenges and opportunities to learn and grow, making it a truly rewarding place to work.
                                </p>
                            </div>
                            <div className='flex flex-row justify-end items-end w-full'>
                                <img 
                                    src={BottomQuote} 
                                    alt='TopQuote' 
                                    className='
                                        w-[20px]
                                        md:w-[35px]
                                        lg:w-[50px]
                                    ' 
                                />
                            </div>
                            <div className='lg:px-12 md:px-9 px-6 lg:leading-6 md:leading-4 leading-2'>
                                <h1 className='font-semibold lg:text-[27px] text-[13.85px]'>Teth Gamboa</h1>
                                <p className='lg:text-[23px] text-[12px]'>VP for Operations</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row lg:p-10 gap-2'>
                        <img 
                            src={GladysManalili} 
                            alt='Gladys Manalili' 
                            className='
                                w-full h-[302px]
                                md:w-[300px]
                                lg:w-[418px] lg:h-[548px]
                                lg:order-2 order-1
                            '
                        />
                        <div className='flex flex-col w-full lg:order-1 order-2'>
                            <div className='flex flex-row justify-start items-start'>
                                <img 
                                    src={TopQuote} 
                                    alt='TopQuote' 
                                    className='
                                        w-[20px]
                                        md:w-[35px]
                                        lg:w-[50px]
                                    ' 
                                />
                            </div>
                            <div className='flex flex-row justify-center items-center w-full'>
                                <p className='lg:text-[26px] lg:px-12 md:px-9 text-[15.39px] px-6 lg:leading-9 md:leading-5 leading-2'>
                                    I started my career at Prosynergy Inc. Working in such a dynamic environment for more than 5 years has helped me gain significant knowledge and experience in the banking industry particularly in various transactions with government agencies related to Vehicle and Real Property which is in line with my profession as an accountancy graduate. The commitment of the company to get things done while providing the best service to its clients has taught me to be resourceful and versatile to any changes we might encounter. The leadership and unwavering support from the management promotes a healthy working environment which makes PSI an enjoyable and meaningful working experience.
                                </p>
                            </div>
                            <div className='flex flex-row justify-end items-end w-full'>
                                <img 
                                    src={BottomQuote} 
                                    alt='TopQuote' 
                                    className='
                                        w-[20px]
                                        md:w-[35px]
                                        lg:w-[50px]
                                    ' 
                                />
                            </div>
                            <div className='lg:px-12 md:px-9 px-6 lg:leading-6 md:leading-4 leading-2'>
                                <h1 className='font-semibold lg:text-[27px] text-[13.85px]'>Gladys Manalili</h1>
                                <p className='lg:text-[23px] text-[12px]'>Finance Officer</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </StyledSwiper>
        </div>
    )
}

export default Testimonial

import React from 'react';
import ReactPlayer from 'react-player/youtube';

const HeroModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div
            id="static-modal"
            tabIndex={-1}
            aria-hidden="true"
            className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-50 overflow-y-auto overflow-x-hidden"
        >
            <div className='relative flex max-h-full w-full h-full bg-white rounded-lg shadow-lg'>
                <ReactPlayer
                    url='https://www.youtube.com/watch?v=NwKEwxiH4PI'
                    playing={isOpen}
                    loop={true}
                    controls={false}
                    width='100%'
                    height='100%'
                    className='absolute top-0 left-0'
                />
                <button
                    onClick={onClose}
                    className="absolute top-2 right-3 bg-white text-black rounded-full p-2 focus:outline-none"
                >
                    <i className="fa-regular fa-xl fa-circle-xmark"></i>                
                </button>
            </div>
        </div>
    );
}

export default HeroModal;

import React, { useState, useEffect } from 'react';
import TopBar from '../../components/admin/TopBar';
import CreateHiringModal from '../../components/admin/CreateHiringModal';
import EditHiringModal from '../../components/admin/EditHiringModal';
import axios from 'axios';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { ucwords } from '../../utils/Ucwords';

const PositionPage = () => {
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [hirings, setHirings] = useState([]);
    const [selectedHiring, setSelectedHiring] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const openCreateModal = () => setIsCreateModalOpen(true);
    const closeCreateModal = () => setIsCreateModalOpen(false);

    const openEditModal = (hiring) => {
        setSelectedHiring(hiring);
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setSelectedHiring(null);
        setIsEditModalOpen(false);
    };

    const fetchHirings = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get('http://localhost:3002/hirings', {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setHirings(response.data);
        } catch (error) {
            console.error('Error fetching hirings:', error);
        }
    };

    useEffect(() => {
        fetchHirings();
    }, []);

    const handleEdit = (hiring) => {
        openEditModal(hiring);
    };

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel'
        });
    
        if (result.isConfirmed) {
            try {
                const token = localStorage.getItem('token'); // Retrieve token from local storage
                const deleteHiring = await axios.delete(`http://localhost:3002/hirings/${id}`, {
                    headers: {
                      'Authorization': `Bearer ${token}` // Attach token to headers
                    }
                });
                const { message } = deleteHiring.data;
                fetchHirings();

                // Show success message
                Swal.fire({
                    title: 'Deleted!',
                    text: message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 3000
                });
            } catch (error) {
                console.error('Error deleting hiring position:', error);
                Swal.fire(
                    'Error!',
                    'There was an error deleting the position.',
                    'error'
                );
            }
        }
    };
    
    const filterHirings = hirings.filter(hiring => {
        return (
            `${ucwords(hiring.position)} ${ucwords(hiring.branch)}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
            hiring.qualifications.toLowerCase().includes(searchTerm.toLowerCase()) ||
            hiring.responsibilities.toLowerCase().includes(searchTerm.toLowerCase()) ||
            hiring.description.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });
    

const columns = [
        {
            name: 'Position',
            selector: row => `${ucwords(row.position)}`,
            sortable: true,
        },
        {
            name: 'Branch',
            selector: row => `${ucwords(row.branch)}`,
            sortable: true,
        },
        {
            name: 'Description',
            selector: row => `${ucwords(row.description)}`,
            sortable: true,
        },
        {
            name: 'Responsibilities',
            selector: row => `${ucwords(row.responsibilities)}`,
            sortable: true,
        },
        {
            name: 'Qualifications',
            selector: row => `${ucwords(row.qualifications)}`,
            sortable: true,
        },
        {
            name: 'Hiring Status',
            selector: row => row.hiring_status === 0 ? 'On-going' : 'Completed',
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => (
                <div className="flex space-x-2">
                    <button
                        className="text-blue-500 hover:text-blue-700"
                        onClick={() => handleEdit(row)}
                    >
                        <i className="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button
                        className="text-red-500 hover:text-red-700"
                        onClick={() => handleDelete(row.id)}
                    >
                        <i className="fa-solid fa-trash"></i>
                    </button>
                </div>
            ),
        }
    ];

    const customStyles = {
        headCells: { style: { fontWeight: 'bold', fontSize: '14px' } },
    };

    return (
        <>
            <TopBar />
            <CreateHiringModal isOpen={isCreateModalOpen} onClose={closeCreateModal} onSuccess={fetchHirings} />
            <EditHiringModal isOpen={isEditModalOpen} onClose={closeEditModal} onSuccess={fetchHirings} hiring={selectedHiring} />

            <div className="p-2 mt-16">
                <div className="flex flex-col">
                    <div className="flex pb-2 justify-between border-b-2">
                        <div className="font-semibold text-xl">
                            <label>List of Hiring</label>
                        </div>
                        <button onClick={openCreateModal} className="text-white bg-[var(--customRed)] hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                            Create Hiring
                        </button>
                    </div>
                    <div className='mt-3'>
                        <div className='flex justify-end gap-3'>
                            <label htmlFor='search' className='mt-1.5'>Search:</label>
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="form-input p-1.5 block border-gray-300 rounded-lg shadow-sm focus:ring-green-800 focus:border-green-800 mb-1"
                            />
                        </div>
                        <DataTable
                            columns={columns}
                            data={filterHirings}
                            pagination
                            responsive
                            highlightOnHover
                            pointerOnHover
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                            customStyles={customStyles}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PositionPage;
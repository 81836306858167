import React from 'react';
import autoBg from '../../assets/images/service/autoBg.png';
import Auto from '../../components/user/Auto';
import Footer from '../../components/common/Footer';
import Navbar from '../../components/common/Navbar';

const UserAuto = () => {
    return (
        <div>
            <Navbar />
            <div 
                className='relative flex flex-col justify-center lg:h-[50vh] bg-cover lg:bg-contain bg-no-repeat mt-[60px] lg:mt-[31px] bg-center z-0'
                style={{ 
                    backgroundImage: `url(${autoBg})`,
                    backgroundBlendMode: 'multiply',
                }}
            >
                <div className="absolute top-0 left-0 w-full h-full lg:h-[43.5vh] bg-blend-[multiply] bg-[#214A21] bg-opacity-[0.30] z-1"></div>
                <div className='relative text-white px-4 sm:px-8 md:px-12 lg:px-16 py-4 sm:py-6 md:py-8 lg:py-10 z-2'>
                    <h1 className='text-xl sm:text-xl md:text-2xl lg:text-3xl font-bold'>Auto & Chattel Services</h1>
                    <p className='mt-2 text-sm sm:text-base md:text-lg lg:text-xl'>
                        We offer comprehensive Auto & Chattel Services designed to meet your unique needs. Our expert team provides top-notch solutions, ensuring the security and efficiency of your auto assets.           
                    </p>
                </div>
            </div>
            <div className='py-5'>
                <Auto/>
            </div>
            <Footer />
        </div>
    );
};

export default UserAuto;

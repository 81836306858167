import React from 'react'
import CompanyBg from '../../assets/images/company/CompanyStory.png';

const CompanyStory = () => {
    return (
        <div className='flex sm:flex-row flex-col w-full lg:h-screen h-full py-5 justify-center sm:gap-0 gap-5 items-center mt-20 md:mt-0'>
            <div className='w-full'>
                <img className="h-auto max-w-full" src={CompanyBg} alt="image1 description" />
            </div>
            <div className='flex flex-col gap-5 sm:px-10 px-5 w-full md:mt-16'>
                <h1 className='font-bold text-3xl sm:text-[55px] md:leading-[2.7rem]'>The ProSynergy Story</h1>
                <p className='text-base font-normal sm:text-[22px] leading-[1.4]'>Founded in 2014 with a vision to become the premier provider of business support solutions, ProSynergy Inc. has earned a reputation as a reliable partner for banks, financial institutions, and retailers nationwide. At ProSynergy Inc., we prioritize our clients, delivering innovative and cost-efficient solutions with excellence and integrity. Our expertise spans a wide range of markets, including real estate, auto, and chattel services. We are also expanding our services to the Filipino community in the US. We invest in the professional growth and well-being of our employees to ensure a competent and motivated workforce, dedicated to meeting our clients' needs.</p>
            </div>
        </div>
    )   
}

export default CompanyStory

import React, { useState } from 'react';
import auto1 from '../../assets/images/service/auto1.png';
import auto2 from '../../assets/images/service/auto2.png';
import auto3 from '../../assets/images/service/auto3.png';
import auto4 from '../../assets/images/service/auto4.png';
import auto5 from '../../assets/images/service/auto5.png';
import auto6 from '../../assets/images/service/auto6.png';
import ConsultationModal from './ConsultationModal';

const Auto = () => {
    const [isOpen, setIsOpen] = useState([false]);
    const [isConsultModalOpen, setIsConsultModalOpen] = useState(false);

    const toggleAccordion = (index) => {
        const newIsOpen = [...isOpen];
        newIsOpen[index] = !newIsOpen[index];
        setIsOpen(newIsOpen);
    };

    return (
        <>
            <ConsultationModal isOpen={isConsultModalOpen} onClose={() => setIsConsultModalOpen(false)} />
            <div className='grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 sm:gap-10 gap-5 px-5 w-full sm:px-10 mb-10'>
                {/* 1 */}
                <div
                    className='h-full'
                >
                    <div 
                        className='relative flex flex-row justify-center items-end bg-cover bg-no-repeat bg-center h-52 rounded-t-xl'
                        style={{ backgroundImage: `url(${auto1})` }}
                    >
                        <div className="absolute inset-0 bg-black bg-opacity-50 rounded-t-xl z-0"></div>
                        <p 
                            className="relative lg:text-[23px] md:text-[20px] text-[17px] text-white text-center leading-tight mb-3 z-10"
                        >
                            Chattel Mortgage
                            <br />
                            & LTO Annotation
                        </p>
                    </div>
                    <div
                        className='rounded-b-xl'
                        style={{ boxShadow: 'inset -10px 0px 10px -10px rgba(0, 0, 0, 0.15), inset 10px 0px 10px -10px rgba(0, 0, 0, 0.15)'}}
                    >
                        <div className="px-5 pt-3">
                            <p className={`mb-3 font-normal text-gray-700 ${isOpen[1] ? '' : 'line-clamp-4'}`}>
                                Facilitates auto loan registration with the Registry of Deeds and manages LTO encumbrance/annotation through various accredited LTO agencies.
                                <br />
                                <br />
                            </p>
                        </div>
                        <div className={`${isOpen[1] ? 'block' : 'hidden'}`}>
                            <div className='flex flex-col gap-5 px-5'>
                                <div className='flex flex-row items-center justify-center'>
                                    <button
                                        type="button"
                                        className="text-white bg-[#214A21] focus:ring-2 w-full lg:w-[311px] lg:h-[46.01px] text-[17px] focus:outline-none focus:ring-green-300 rounded-full py-2 text-center font-medium"
                                        onClick={() => setIsConsultModalOpen(true)}
                                    >
                                        Send an Inquiry
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="flex flex-col text-[#446444] items-center justify-center w-full p-5 font-medium rounded-xl shadow-xl"
                            aria-expanded={isOpen[1]}
                            onClick={() => toggleAccordion(1)}
                        >
                            <span>{isOpen[1] ? 'See Less' : 'See More'}</span>
                            <i className={`fa-solid ${isOpen[1] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                        </button>
                    </div>
                </div>

                {/* 2 */}
                <div
                    className='h-full'
                >
                    <div 
                        className='relative flex flex-row justify-center items-end bg-cover bg-no-repeat bg-center h-52 rounded-t-xl'
                        style={{ backgroundImage: `url(${auto2})` }}
                    >
                        <div className="absolute inset-0 bg-black bg-opacity-50 rounded-t-xl z-0"></div>
                        <p 
                            className="relative lg:text-[23px] md:text-[20px] text-[17px] text-white text-center leading-tight mb-3 z-10"
                        >
                            Vehicle / Car History
                        </p>
                    </div>
                    <div
                        className='rounded-b-xl'
                        style={{ boxShadow: 'inset -10px 0px 10px -10px rgba(0, 0, 0, 0.15), inset 10px 0px 10px -10px rgba(0, 0, 0, 0.15)'}}
                    >
                        <div className="px-5 pt-3">
                            <p className={`mb-3 font-normal text-gray-700 ${isOpen[2] ? '' : 'line-clamp-4'}`}>
                                Verifies and validates vehicle ownership registration from LTO agencies to establish ownership and authenticity of supporting documents. Ensures the vehicle is free from liens or legal impediments and is acceptable as collateral.
                            </p>
                        </div>
                        <div 
                            className={`${isOpen[2] ? 'block' : 'hidden'}`}
                        >
                            <div className='flex flex-col gap-5 px-5'>
                                {/* <div>
                                    <p className='font-semibold text-gray-700'><i className="fa-solid fa-lg fa-file-circle-check"></i> Requirements:</p>
                                    <ul className='list-disc px-5'>
                                        <li>Copy of OR/CR</li>
                                        <li>Copy of ID of owner</li>
                                        <li>Authorization letter (authorizing Prosynergy)</li>
                                    </ul>
                                </div>  */}
                                <div className='flex flex-row items-center justify-center'>
                                    <button 
                                        type="button" 
                                        className="text-white bg-[#214A21] focus:ring-2 w-full lg:w-[311px] lg:h-[46.01px] text-[17px] focus:outline-none focus:ring-green-300 rounded-full py-2 text-center font-medium"
                                        onClick={() => setIsConsultModalOpen(true)}
                                        >
                                        Send an Inquiry
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button 
                            type="button" 
                            className="flex flex-col text-[#446444] items-center justify-center w-full p-5 font-medium rounded-xl shadow-xl" 
                            aria-expanded={isOpen[2]}
                            onClick={() => toggleAccordion(2)}
                        >   
                            
                            <span>{isOpen[2] ? 'See Less' : 'See More'}</span>
                            <i className={`fa-solid ${isOpen[2] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                        </button>
                    </div>
                </div>

                {/* 3 */}
                <div
                    className='h-full'
                >
                    <div 
                        className='relative flex flex-row justify-center items-end bg-cover bg-no-repeat bg-center h-52 rounded-t-xl'
                        style={{ backgroundImage: `url(${auto3})` }}
                    >
                        <div className="absolute inset-0 bg-black bg-opacity-50 rounded-t-xl z-0"></div>
                        <p 
                            className="relative lg:text-[23px] md:text-[20px] text-[17px] text-white text-center leading-tight mb-3 z-10"
                        >
                            Vehicle Transfer of Ownership 
                            <br />
                            with Cancellation of Mortgage
                        </p>
                    </div>
                    <div                        
                        className='rounded-b-xl'
                        style={{ boxShadow: 'inset -10px 0px 10px -10px rgba(0, 0, 0, 0.15), inset 10px 0px 10px -10px rgba(0, 0, 0, 0.15)'}}
                    >
                        <div className="px-5 pt-3">
                            <p className={`mb-3 font-normal text-gray-700 ${isOpen[3] ? '' : 'line-clamp-4'}`}>
                                Facilitates the transfer of ownership from seller to buyer and coordinates with relevant agencies to expedite the process.
                                <br />
                                <br />
                            </p>
                        </div>
                        <div 
                            className={`${isOpen[3] ? 'block' : 'hidden'}`}
                        >
                            <div className='flex flex-col gap-5 px-5'>
                                {/* <div>
                                    <p className='font-semibold text-gray-700'><i className="fa-solid fa-lg fa-file-circle-check"></i> Requirements:</p>
                                    <ul className='list-disc px-5'>
                                        <li>Notarized Original Deed of Sale</li>
                                        <li>Original OR/CR</li>
                                        <li>PNP Clearance (HPG)</li>
                                        <li>TPL insurance</li>
                                        <li>Photocopy of Valid IDs (Seller & Buyer)</li>
                                        <li>Cancellation of Mortgage with RD stamp</li>
                                        <li>Original RD OR</li>
                                        <li>Stencil of Chassis & Engine no. (in LTO blue form)</li>
                                    </ul>
                                </div> */}
                                <div className='flex flex-row items-center justify-center'>
                                    <button 
                                        type="button" 
                                        className="text-white bg-[#214A21] focus:ring-2 w-full lg:w-[311px] lg:h-[46.01px] text-[17px] focus:outline-none focus:ring-green-300 rounded-full py-2 text-center font-medium"
                                        onClick={() => setIsConsultModalOpen(true)}
                                        >
                                        Send an Inquiry
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button 
                            type="button" 
                            className="flex flex-col text-[#446444] items-center justify-center w-full p-5 font-medium rounded-xl shadow-xl" 
                            aria-expanded={isOpen[3]}
                            onClick={() => toggleAccordion(3)}
                        >   
                            
                            <span>{isOpen[3] ? 'See Less' : 'See More'}</span>
                            <i className={`fa-solid ${isOpen[3] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                        </button>
                    </div>
                </div>

                {/* 4 */}
                <div 
                    className='h-full'
                >
                    <div 
                        className='relative flex flex-row justify-center items-end bg-cover bg-no-repeat bg-center h-52 rounded-t-xl'
                        style={{ backgroundImage: `url(${auto4})` }}
                    >
                        <div className="absolute inset-0 bg-black bg-opacity-50 rounded-t-xl z-0"></div>
                        <p 
                            className="relative lg:text-[23px] md:text-[20px] text-[17px] text-white text-center leading-tight mb-3 z-10"
                        >
                            Reconstitution of Vehicle 
                            <br />
                            Certificate of Registration   
                        </p>
                    </div>
                    <div                       
                        className='rounded-b-xl'
                        style={{ boxShadow: 'inset -10px 0px 10px -10px rgba(0, 0, 0, 0.15), inset 10px 0px 10px -10px rgba(0, 0, 0, 0.15)'}}
                    >
                        <div className="px-5 pt-3">
                            <p className={`mb-3 font-normal text-gray-700 ${isOpen[4] ? '' : 'line-clamp-4'}`}>
                                Assists in reconstituting or duplicating the Certificate of Registration if it is lost or cannot be found.
                                <br />
                                <br />
                                <br />
                            </p>
                        </div>

                        <div className={`${isOpen[4] ? 'block' : 'hidden'}`}>
                            <div className='flex flex-col gap-5 px-5'>
                                <div className='flex flex-row items-center justify-center'>
                                    <button 
                                        type="button" 
                                        className="text-white bg-[#214A21] focus:ring-2 w-full lg:w-[311px] lg:h-[46.01px] text-[17px] focus:outline-none focus:ring-green-300 rounded-full py-2 text-center font-medium"
                                        onClick={() => setIsConsultModalOpen(true)}
                                    >
                                        Send an Inquiry
                                    </button>
                                </div>
                            </div>
                        </div>

                        <button 
                            type="button" 
                            className="flex flex-col text-[#446444] items-center justify-center w-full p-5 font-medium rounded-xl shadow-xl" 
                            aria-expanded={isOpen[4]}
                            onClick={() => toggleAccordion(4)}
                        >   
                            <span>{isOpen[4] ? 'See Less' : 'See More'}</span>
                            <i className={`fa-solid ${isOpen[4] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                        </button>
                    </div>
                </div>


                {/* 5 */}
                <div className='h-full'>
                    <div 
                        className='relative flex flex-row justify-center items-end bg-cover bg-no-repeat bg-center h-52 rounded-t-xl' 
                        style={{ backgroundImage: `url(${auto5})` }}
                    >
                        <div className="absolute inset-0 bg-black bg-opacity-50 rounded-t-xl z-0"></div>
                        <p 
                            className="relative lg:text-[23px] md:text-[20px] text-[17px] text-white text-center leading-tight mb-3 z-10"
                            style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}
                        >
                            Registration and 
                            <br />
                            Renewal of Vehicle  
                        </p>
                    </div>
                    <div                        
                        className='rounded-b-xl'
                        style={{ boxShadow: 'inset -10px 0px 10px -10px rgba(0, 0, 0, 0.15), inset 10px 0px 10px -10px rgba(0, 0, 0, 0.15)'}}
                    >
                        <div className="px-5 pt-3">
                            <p className={`mb-3 font-normal text-gray-700 ${isOpen[5] ? '' : 'line-clamp-4'}`}>
                                Assists with annual vehicle registration and coordinates with LTO's record section and other departments as part of the administrative process.
                                <br />
                                <br />
                            </p>
                        </div>

                        <div className={`${isOpen[5] ? 'block' : 'hidden'}`}>
                            <div className='flex flex-col gap-5 px-5'>
                                <div className='flex flex-row items-center justify-center'>
                                    <button 
                                        type="button" 
                                        className="text-white bg-[#214A21] focus:ring-2 w-full lg:w-[311px] lg:h-[46.01px] text-[17px] focus:outline-none focus:ring-green-300 rounded-full py-2 text-center font-medium"
                                        onClick={() => setIsConsultModalOpen(true)}
                                    >
                                        Send an Inquiry
                                    </button>
                                </div>
                            </div>
                        </div>

                        <button 
                            type="button" 
                            className="flex flex-col text-[#446444] items-center justify-center w-full p-5 font-medium rounded-xl shadow-xl" 
                            aria-expanded={isOpen[5]}
                            onClick={() => toggleAccordion(5)}
                        >   
                            <span>{isOpen[5] ? 'See Less' : 'See More'}</span>
                            <i className={`fa-solid ${isOpen[5] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                        </button>
                    </div>
                </div>


                {/* 6 */}
                <div className='h-full'>
                    <div 
                        className='relative flex flex-row justify-center items-end bg-cover bg-no-repeat bg-center h-52 rounded-t-xl'
                        style={{ backgroundImage: `url(${auto6})` }}
                    >
                        <div className="absolute inset-0 bg-black bg-opacity-60 rounded-t-xl z-0"></div>

                        <p 
                            className="relative lg:text-[23px] md:text-[20px] text-[17px] text-white text-center leading-tight mb-3 z-10" 
                        >
                            Handles ROPA and 
                            <br />
                            Vehicle Appraisal
                        </p>
                    </div>
                    <div
                        className='rounded-b-xl'
                        style={{ boxShadow: 'inset -10px 0px 10px -10px rgba(0, 0, 0, 0.15), inset 10px 0px 10px -10px rgba(0, 0, 0, 0.15)'}}
                    >
                        <div className="px-5 pt-3">
                            <p className={`mb-3 font-normal text-gray-700 ${isOpen[6] ? '' : 'line-clamp-4'}`}>
                                Manages all security collaterals under ROPA by providing necessary accounting-inventory processes and determining the exact collateral position or value.
                                <br />
                                <br />
                            </p>
                        </div>

                        <div className={`${isOpen[6] ? 'block' : 'hidden'}`}>
                            <div className='flex flex-col gap-5 px-5'>
                                <div className='flex flex-row items-center justify-center'>
                                    <button 
                                        type="button" 
                                        className="text-white bg-[#214A21] focus:ring-2 w-full lg:w-[311px] lg:h-[46.01px] text-[17px] focus:outline-none focus:ring-green-300 rounded-full py-2 text-center font-medium"
                                        onClick={() => setIsConsultModalOpen(true)}
                                        >
                                        Send an Inquiry
                                    </button>
                                </div>
                            </div>
                        </div>

                        <button 
                            type="button" 
                            className="flex flex-col text-[#446444] items-center justify-center w-full p-5 font-medium rounded-xl shadow-xl" 
                            aria-expanded={isOpen[6]}
                            onClick={() => toggleAccordion(6)}
                        >   
                            <span>{isOpen[6] ? 'See Less' : 'See More'}</span>
                            <i className={`fa-solid ${isOpen[6] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                        </button>
                    </div>
                </div>
            </div>
        </>

    );
};

export default Auto;
